import React from 'react';
import style from './PanelHeader.scss';
import { Intl } from '@/shared/intl/intl-component';

export default class PanelHeader extends  React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        let { trailing = [] } = this.props ?? {};

        if (!Array.isArray(trailing)) {
            trailing = [trailing];
        }

        return <div className={style.PanelHeader}>
            <div className={style.label}><Intl token="pagesPanel.settings.label" /></div>
            <div className={style.controls}>
                {trailing.map((widget, idx)=> <PanelControl key={idx}>{widget}</PanelControl>)}
            </div>
        </div>;
    }
}

function PanelControl({ children = [] } = {}) {
    return <div className={style.panelControl}>{children}</div>;
}
