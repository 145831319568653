/**
 * Makes a request to the outer frame, passing [requestData] as input args.  Will wait for a response on channel's port.
 *
 * @return {Promise<*>}
 */
export async function executeFrameRequest(keapRequestType, requestData = null, origin = '*', windowParent = 'top') {
    return new Promise((resolve, reject) => {
        const channel = new MessageChannel();

        channel.port1.onmessage = ({ data: { success, response, error } }) => {
            try {
                success ? resolve(response) : reject(error);
            } finally {
                channel.port1.close();
            }
        };
        channel.port1.start();

        window[windowParent].postMessage({ keapRequestType, requestData }, origin, [channel.port2]);
    });
}
