/**
 * The compiled form css.  To regenerate this file run `yarn css` or `yarn css:watch`
 * @type string
 */
import formStyles from './styles/form-compiled.css.raw';
import template from 'lodash/template';
import { isNonEmptyString } from '@/shared/string-utils';
import isPlainObject from 'lodash/isPlainObject';
import kebabCase from 'lodash/kebabCase';
import { isEmptyValue } from '@/hosting/keap-hosting-util';

// eslint-disable-next-line no-unused-vars
import { formToolOpts } from '@/unlayer-tools/unlayer-form-tool/form_tool.constants';


/**
 * A lodash template to inject the style variables into the compiled form css.
 */
const FORM_CSS_TEMPLATE = template(`
div[id="<%= htmlID %>"] {
    <%= styleVariables %>
}

${formStyles}
`);

/**
 * Unlayer form style block, which used to be inlined, now rendered in the head as a single block.
 *
 * @param values Configuration values for the tool.
 * @return {string} CSS that can be exported to the head.
 */
export function buildFormCSS({ values = {} }) {
    const { htmlID } = values._meta ?? {};
    const styles = buildFormVariables(values);
    const styleVariables = Object.entries(styles)
        .reduce((prev, [name, value]) => {
            if (!isEmptyValue(value)) {
                prev += `--${kebabCase(name)}: ${value};
`;
            }

            return prev;
        }, '');

    return FORM_CSS_TEMPLATE({ htmlID, styleVariables });
}

/**
 * Extracts a flattened collection of form style variables.  This method should only deal with styles that can be set
 * by the end-user. Any styles that are static should be defined directly within the CSS
 * @param {object} values Tool configuration values.  see {@link formToolOpts}
 */
function buildFormVariables(values) {
    const {
        // Button styles
        formWidth: _formWidth,

        // Form
        formAlign,

        // Fields
        fieldBorder,
        fieldPadding,
        fieldColor,
        fieldFontSize,
        fieldFontFamily: fieldFontFamilyValue,
        fieldWidth,
        fieldDistance,
        fieldBorderRadius,
        fieldBackgroundColor,

        // Labels
        labelAlign,
        labelColor,
        labelFontSize,
        labelPadding,
        labelFontFamily: labelFontFamilyValue,

        // Buttons
        buttonColors,
        buttonFontSize,
        buttonFontFamily: buttonFontFamilyValue,
        buttonBorder = {},
        buttonBorderRadius,
        buttonPadding,
        buttonAlign,
        buttonWidth: _buttonWidth,
        buttonMargin,
    } = values;

    const formWidths =
        _formWidth && isPlainObject(_formWidth)
            ? {
                autoWidth: Boolean(_formWidth.autoWidth),
                fullWidthOnMobile: Boolean(_formWidth.fullWidthOnMobile),
                width:
                    isNonEmptyString(_formWidth.width)
                        ? _formWidth.width
                        : '100%',
            }
            : {
                autoWidth: false,
                fullWidthOnMobile: false,
                width: isNonEmptyString(_formWidth) ? _formWidth : '100%',
            };

    const buttonWidths =
        _buttonWidth && isPlainObject(_buttonWidth)
            ? {
                autoWidth: Boolean(_buttonWidth.autoWidth),
                fullWidthOnMobile: Boolean(_buttonWidth.fullWidthOnMobile),
                width: isNonEmptyString(_buttonWidth.width) ? _buttonWidth.width : '50%',
            }
            : {
                autoWidth: false,
                fullWidthOnMobile: false,
                width: isNonEmptyString(_buttonWidth) ? _buttonWidth : '50%',
            };

    const formWidth = (formWidths?.width && !formWidths.autoWidth) ? formWidths.width : 'auto';
    const mobileFormWidth = values._override?.mobile?.formWidth?.width;
    const mobileButtonWidth = values._override?.mobile?.buttonWidth?.width;
    const formMaxWidth = formWidth.width;
    const labelFontFamily = labelFontFamilyValue?.value;

    let formJustifyContent;

    switch (formAlign) {
    case 'center':
        formJustifyContent = 'center';
        break;
    case 'left':
        formJustifyContent = 'flex-start';
        break;
    case 'right':
        formJustifyContent = 'flex-end';
        break;
    }

    const fieldFontFamily = fieldFontFamilyValue?.value;

    const errorColor = '#ED0008';
    const buttonWidth = buttonWidths.width && !buttonWidths.autoWidth ? buttonWidths.width : 'auto';
    const buttonFontFamily = buttonFontFamilyValue?.value;


    const spinnerWidth =
        buttonAlign === 'center'
            ? '100%'
            : buttonWidth.width && !buttonWidth.autoWidth
                ? buttonWidth.width
                : 'auto';
    const buttonLeft = buttonAlign === 'right' ? 'auto' : 0;
    const buttonRight = buttonAlign === 'left' ? 'auto' : 0;

    const {
        color: buttonColor,
        hoverColor: buttonHoverColor,
        backgroundColor: buttonBackgroundColor,
        hoverBackgroundColor: buttonHoverBackgroundColor,
    } = buttonColors;


    const {
        borderTopWidth: buttonBorderTopWidth,
        borderTopStyle: buttonBorderTopStyle,
        borderTopColor: buttonBorderTopColor,
        borderRightWidth: buttonBorderRightWidth,
        borderRightStyle: buttonBorderRightStyle,
        borderRightColor: buttonBorderRightColor,
        borderBottomWidth: buttonBorderBottomWidth,
        borderBottomStyle: buttonBorderBottomStyle,
        borderBottomColor: buttonBorderBottomColor,
        borderLeftWidth: buttonBorderLeftWidth,
        borderLeftStyle: buttonBorderLeftStyle,
        borderLeftColor: buttonBorderLeftColor,
    } = buttonBorder;

    const {
        borderTopWidth: fieldBorderTopWidth,
        borderTopStyle: fieldBorderTopStyle,
        borderTopColor: fieldBorderTopColor,
        borderRightWidth: fieldBorderRightWidth,
        borderRightStyle: fieldBorderRightStyle,
        borderRightColor: fieldBorderRightColor,
        borderBottomWidth: fieldBorderBottomWidth,
        borderBottomStyle: fieldBorderBottomStyle,
        borderBottomColor: fieldBorderBottomColor,
        borderLeftWidth: fieldBorderLeftWidth,
        borderLeftStyle: fieldBorderLeftStyle,
        borderLeftColor: fieldBorderLeftColor,
    } = fieldBorder;

    const radioBorderColor = fieldBorderTopColor;
    const checkboxBorderColor = fieldBorderTopColor;

    const checkboxLabelFontFamily = fieldFontFamily;
    const checkboxLabelFontSize = fieldFontSize;
    const checkboxLabelFontColor = fieldColor;

    const radioLabelFontFamily = fieldFontFamily;
    const radioLabelFontSize = fieldFontSize;
    const radioLabelFontColor = fieldColor;

    return {
        buttonBackgroundColor,
        buttonBorderBottomColor,
        buttonBorderBottomStyle,
        buttonBorderBottomWidth,
        buttonBorderLeftColor,
        buttonBorderLeftStyle,
        buttonBorderLeftWidth,
        buttonBorderRadius,
        buttonBorderRightColor,
        buttonBorderRightStyle,
        buttonBorderRightWidth,
        buttonBorderTopColor,
        buttonBorderTopStyle,
        buttonBorderTopWidth,
        buttonColor,
        buttonFontFamily,
        buttonFontSize,
        buttonHoverBackgroundColor,
        buttonHoverColor,
        buttonLeft,
        buttonAlign,
        buttonMargin,
        buttonPadding,
        buttonRight,
        buttonWidth,
        errorColor,
        fieldBackgroundColor,
        fieldBorderBottomColor,
        fieldBorderBottomStyle,
        fieldBorderBottomWidth,
        fieldBorderLeftColor,
        fieldBorderLeftStyle,
        fieldBorderLeftWidth,
        fieldBorderRadius,
        fieldBorderRightColor,
        fieldBorderRightStyle,
        fieldBorderRightWidth,
        fieldBorderTopColor,
        fieldBorderTopStyle,
        fieldBorderTopWidth,
        fieldColor,
        fieldDistance,
        fieldFontFamily,
        fieldFontSize,
        fieldPadding,
        fieldWidth,
        formJustifyContent,
        formMaxWidth,
        formWidth,
        labelAlign,
        labelColor,
        labelFontFamily, // new
        labelFontSize,
        labelPadding,
        mobileFormWidth,
        mobileButtonWidth,
        radioBorderColor,
        radioLabelFontColor,
        radioLabelFontFamily,
        radioLabelFontSize,
        checkboxBorderColor,
        checkboxLabelFontColor,
        checkboxLabelFontFamily,
        checkboxLabelFontSize,
        spinnerWidth,
    };
}
