import React from 'react';
import FormFieldContainer from '@/unlayer-tools/unlayer-form-tool/renderer/formFields/FormFieldContainer';

const BooleanField = (props) => {

    const { field, error, onValue,  className = '' } = props;

    const checkboxStyles = {
        verticalAlign: 'middle',
    };

    const labelStyle = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '5px',
    };

    const labelTextStyle = {
        marginLeft: '5px',
    };

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    };

    return (
        <FormFieldContainer
            formControl={
                <div style={containerStyle}>
                    <label key="option-yes" style={labelStyle}>
                        <input type="radio" name={field.name} value="true" style={checkboxStyles} onChange={(e) => onValue(e.target.value, e.target.checked)} />
                        <span className="checkbox-label" style={labelTextStyle}>Yes</span>
                    </label>
                    <label key="option-no" style={labelStyle}>
                        <input type="radio" name={field.name} value="false" style={checkboxStyles} onChange={(e) => onValue(e.target.value, e.target.checked)} />
                        <span className="checkbox-label" style={labelTextStyle}>No</span>
                    </label>
                </div>
            }
            fieldType="boolean"
            error={error}
            field={field}
            className={className}
        />
    );


};

export default BooleanField;
