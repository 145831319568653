import { executeFrameRequest } from '@/unlayer-tools/requests/frame-request';

/**
 * Requests a list of options from the outer frame that can be passed to a select widget  @see {@link KeapSelectEditor}.
 *
 * This makes it so:
 *
 * a) we don't have to pass the options through the frame on startup
 * b) we can get updates to option lists without having to reload the entire unlayer frame
 *
 * @see {@link KeapSelectEditor}
 * @return {Promise<[]>}
 */
export async function getSelectOptions(optionType) {
    // lodash/capitalize doesn't work because it makes the rest of the string lowercase
    const dataType = optionType.charAt(0).toUpperCase() + optionType.slice(1);

    const requestType = `get${dataType}Options`;

    return executeFrameRequest(requestType);
}

