import merge from 'lodash/merge';
import get from 'lodash/get';


let allLocaleData = {};

const FALLBACK_LOCALE = 'en';
let currentLocale = FALLBACK_LOCALE;

/**
 * Other locale/message data blocks that are registered to be loaded once the library is initialized.
 * Using this method allows code to register message bundles without having to wait for the localization bundle to
 * fully load.
 *
 * Currently only used in unit tests
 */
const additionalLocales = [];

/**
 * Merged locale data based on the currently set locale
 */
let currentLocaleData = {};

let kmnAccount = '';

let companyName = '';

/**
 * Determines the value of the FF fs.45635.add.form.id.to.keap.form.html in order
 * to add a form-id attribute inside a keap-form component
 * @type {boolean}
 */
let addFormId = false;

function loadMergedLocaleData(locale = FALLBACK_LOCALE) {
    currentLocaleData = { ...allLocaleData[FALLBACK_LOCALE] };

    locale.split(/[-_]/g)
        .reduce((acc, next)=> {
            if (next) {
                acc = !acc ? next : `${acc}-${next.toLowerCase()}`;
                const localeData = allLocaleData[acc];

                if (localeData) {
                    currentLocaleData = merge(currentLocaleData, localeData);
                }
            }

            return acc;
        }, '');


    if (locale !== FALLBACK_LOCALE) {
        currentLocaleData = merge(currentLocaleData, allLocaleData[locale]);
    }
}

export const intl = {
    set currentLocale(locale) {
        currentLocale = locale || FALLBACK_LOCALE;
        loadMergedLocaleData(locale);
    },

    get isReady() {
        return isLoaded;
    },

    load(localeData) {
        const sanitized = Object.entries(localeData).reduce((acc, [locale, data])=>{
            acc[locale.replace(/_/g, '-').toLowerCase()] = data;

            return acc;
        }, {});

        allLocaleData = merge(allLocaleData, sanitized);

        // Check if we're already loaded so we can rebuild the merged locale data;
        if (isLoaded) {
            loadMergedLocaleData(currentLocale);
        }
    },

    reset() {
        isLoaded = false;
        additionalLocales.length = 0;
    },

    init({
        locale = FALLBACK_LOCALE,
        localeData = {},
    } = {}) {
        if (!isLoaded) {
            allLocaleData = {};
            [...additionalLocales, localeData].forEach(this.load);
            this.currentLocale = locale;
        }
        isLoaded = true;
    },

    /**
     * Mimics the signature of react-intl-universal
     * @param token
     * @return {string}
     */
    formatMessage({ id, defaultMessage }) {
        if (!isLoaded) this.init();
        if (currentLocaleData[id]) return currentLocaleData[id];

        const result = get(currentLocaleData, id.split('.'));

        return result || defaultMessage || id;
    },

    /**
     * Mimics the signature of react-intl-universal library
     * @param token
     * @param defaultMessage Used when there's no i18n code
     * @return {string}
     */
    get(token, { defaultMessage = null } = {}) {
        return this.formatMessage({ id: token, defaultMessage });
    },
};

let isLoaded = false;

/**
 * {@link additionalLocales}
 *
 * @param locale
 */
export function addLocaleData(locale) {
    additionalLocales.push(locale);

    if (isLoaded) {
        intl.load(locale);
    }
}

/**
 * Initializes the intl object, loads all messages, and then switches the isLoaded flag to true.
 */
export const initIntl = ({ locale, localeData = {} } = {}) => {
    if (!isLoaded) {
        intl.init({ locale, localeData });
    }

    return intl;
};

export function setCurrentLocale(locale) {
    if (!intl.isReady) {
        intl.init({ locale:locale });
    } else {
        intl.currentLocale = locale;
    }
}

export function setKmnAccount(kmnAccountParam) {
    kmnAccount = kmnAccountParam;
}

export function getKmnAccount() {
    return kmnAccount;
}

export function setCompanyName(companyNameParam) {
    companyName = companyNameParam;
}

export function getCompanyName() {
    return companyName;
}

export function setAddFormId(addFormIdEnabled) {
    addFormId = addFormIdEnabled;
}

export function isAddFormIdEnabled() {
    return addFormId;
}
