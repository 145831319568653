import React from 'react';
import { FormFieldContainer } from '@/unlayer-tools/unlayer-form-tool/renderer/formFields/FormFieldContainer';

const TextField = (props) => {
    const { field, error, onValue,  className = '' } = props;

    return (
        <FormFieldContainer
            formControl={<input
                type='text'
                name={field.name}
                placeholder={field.placeholder_text}
                className={'form-field'}
                onChange={(e) => onValue(e.target.value)} />}
            fieldType="text"
            error={error}
            field={field}
            className={className}
        />
    );
};

export default TextField;
