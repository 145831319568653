import React from 'react';
import FormFieldContainer from '@/unlayer-tools/unlayer-form-tool/renderer/formFields/FormFieldContainer';

const DateTimeField = (props) => {
    const { field, error, onValue,  className = '' } = props;
    const fieldTypes = ['date', 'time'];

    function getTimeOptions() {
        const hours = 24;
        const minutes = ['00', '15', '30', '45'];
        let options = [];

        const placeholder = '--:-- --';

        options.push(placeholder);

        for (let h = 0; h < hours; h++) {
            let hour = (h % 12 === 0) ? 12 : h % 12;

            if (hour < 10) {
                hour = `0${hour}`;
            }

            for (let m of minutes) {
                const timeOption = `${hour}:${m} ${h >= 12 ? 'PM' : 'AM'}`;

                options.push(timeOption);
            }
        }

        options = options.map((option, index) => {
            return (
                <option value={option === placeholder ? ' ' : option} key={index}>
                    {option}
                </option>
            );
        });

        return options;
    }

    return (
        <div>
            <FormFieldContainer
                formControl={
                    fieldTypes.map((fieldType, index) => {
                        if (fieldType === 'date') {
                            return (
                                <input
                                    key={index}
                                    type={fieldType}
                                    data-input-type={fieldType}
                                    name={field.name}
                                    className="form-field"
                                    autoComplete="off"
                                    onChange={(e) => onValue(e.target.value)}
                                />
                            );
                        }

                        return (
                            <select
                                key={index}
                                name={field.name}
                                className="form-field"
                                onChange={(e) => onValue(e.target.value)}
                            >
                                {
                                    getTimeOptions()
                                }
                            </select>
                        );
                    })
                }
                fieldType="datetime"
                error={error}
                field={field}
                className={className}
            />
        </div>
    );
};

export default DateTimeField;
