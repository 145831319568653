import isPlainObject from 'lodash/isPlainObject';
import { isNonEmptyString } from '@/shared/string-utils';
import React from 'react';
import { intl } from '@/shared/intl';

/**
 * This provides a javascript snippet to listen for page resize events for embedded forms.
 * @param slugName The form slug that's being observed.
 * @return {string} A javascript string snippet
 */
export function listenForResizeEvents(slugName) {
    return `
    window.addEventListener('message', (event) => {
      const { eventType } = event.data;
      if (eventType === 'RESIZE' || eventType === 'keap-forms-resize') {
        const { formSlug, height } = event.data;
        if (formSlug === "${slugName}") {
            const iframe = document.querySelector(\`iframe[data-form-slug="${slugName}"]\`);
            if (iframe) {
                iframe.style.height = \`\${height}px\`;
                iframe.style.minHeight = \`\${height}px\`;
            }  
        }
      }
    });`;
}

/**
 * Resize options shared between embedded component tools.
 * @see {@link SizedContent}
 */
export const sharedToolOpts = () => ({
    layoutOptions: {
        position: 2,
        title: intl.get('tool.sharedOpts.layout', { defaultMessage: 'Layout' }),
        options: {
            formWidth: {
                label: intl.get('tool.properties.formWidth'),
                defaultValue: {
                    autoWidth: false,
                    fullWidthOnMobile: false,
                    width: '100%',
                },
                widget: 'auto_width',
                overrideAllowed: ['mobile'],
            },
            formAlignment: {
                label: intl.get('tool.properties.formAlignment'),
                defaultValue: 'center',
                widget: 'alignment',
            },
        },
    },
});

/**
 * Applies size style components based on the options from {@link sharedToolOpts}
 */
export function SizedContent(props) {
    // Form
    const {
        values: {
            formWidth: _formWidth,
            formAlignment,
        } = {},
    } = props ?? {};

    const formWidths = _formWidth && isPlainObject(_formWidth)
        ? {
            autoWidth: Boolean(_formWidth.autoWidth),
            fullWidthOnMobile: Boolean(_formWidth.fullWidthOnMobile),
            width:
                isNonEmptyString(_formWidth.width)
                    ? _formWidth.width
                    : '100%',
        }
        : {
            autoWidth: false,
            fullWidthOnMobile: false,
            width: isNonEmptyString(_formWidth) ? _formWidth : '100%',
        };

    const formWidth = (formWidths?.width && !formWidths.autoWidth) ? formWidths.width : 'auto';
    const formMaxWidth = formWidth.width;

    let formJustifyContent;

    switch (formAlignment) {
    case 'center':
        formJustifyContent = 'center';
        break;
    case 'left':
        formJustifyContent = 'flex-start';
        break;
    case 'right':
        formJustifyContent = 'flex-end';
        break;
    }


    return (
        <div style={{ display: 'flex', justifyContent: formJustifyContent }}>
            <div style={{ maxWidth: formMaxWidth, width: formWidth }}>
                {props.children}
            </div>
        </div>
    );
}
