import { EnvironmentKind, KeapEnvConfig } from '@/shared/keap-env-config';
import { KeapCustomLinkTypes, registerLookupLinkType } from '@/unlayer-tools/custom-link-types/index';
/**
 * Registers customLinkType for appointmentType.  If no `options` are provided, then a cross-frame request will be made
 * using getSelectOptions('appointmentType');
 *
 * @param options (optional) A list of appointmentTypes to use.  If null or undefined, a cross-frame request will be made
 */

export function registerAppointmentTypeLinkType({ options } = {}) {
    registerLookupLinkType({
        linkType: KeapCustomLinkTypes.keapAppointmentType,
        options, mapper: ({ label, value: bookingLinkType }) => {
            const { bookingLink, environment = EnvironmentKind.integration } = bookingLinkType;
            const value = `${KeapEnvConfig[EnvironmentKind[environment]].EMBED_FORM_URL}/booking/${bookingLink}`;

            return {
                label,
                value,
            };
        },
    });
}

/**
 * Registers customLinkType for checkoutForms.  If no `options` are provided, then a cross-frame request will be made
 * using getSelectOptions('checkoutForm');
 *
 * @param options (optional) A list of checkoutForms to use.  If null or undefined, a cross-frame request will be made
 */
export function registerCheckoutFormLinkType({ options } = {}) {
    registerLookupLinkType({
        linkType: KeapCustomLinkTypes.keapCheckoutForm,
        options, mapper: ({ label, value: checkoutForm }) => {
            const { checkoutFormUrl, appId, environment = EnvironmentKind.integration } = checkoutForm;
            const value = `${KeapEnvConfig[EnvironmentKind[environment]].EMBED_FORM_URL}/checkout/${appId}/${checkoutFormUrl}`;

            return {
                label,
                value,
            };
        },
    });
}
