import React from 'react';
import { Label } from 'reactstrap';
import style from '../../editors/styles/FormEditorStyle.scss';

export const FormFieldContainer = ( { field, error, formControl, fieldType = 'text', className = '' } = {} ) => {
    let cssClass = `input-field ${fieldType}-field`;

    if (error) cssClass += ' error';

    if (fieldType === 'datetime') {
        return (
            <div>
                <div className="field-label">
                    <label className={style.labelStyle}>{field.label}{field.required && ' *'}</label>
                </div>

                <div style={{ display: 'flex' }}>
                    {
                        formControl.map((inputField, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`${cssClass} ${className}`}
                                    style={{ display: field.hidden ? 'none' : 'block', width: '100%' }}
                                    data-required={field.required}
                                    data-name={field.name}
                                    data-type={fieldType}
                                    data-label={field.label}
                                    data-url-param={field.url_param_text}
                                    data-hidden={field.hidden}
                                >
                                    <div style={{ position: 'relative' }} className="input-container">
                                        {inputField}
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }

    return (
        <div className={`${cssClass} ${className}`} style={{ display: field.hidden ? 'none' : 'block' }} data-required={field.required} data-name={field.name} data-type={fieldType} data-label={field.label} data-url-param={field.url_param_text} data-hidden={field.hidden}>
            {field.show_label && <div className="field-label"><Label className={style.labelStyle}>{field.label}{field.required && ' *'}</Label></div>}
            <div style={{ position: 'relative' }} className="input-container">
                {formControl}
            </div>
        </div>
    );
};

export default FormFieldContainer;
