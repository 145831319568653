// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rleHAVlraPw8ZvGNj1vc{transform:scale(0.7)}.KXD86TvwvEcjkNg0zcMZ{display:flex;place-content:space-between;padding:18px 12px 0px}\n", "",{"version":3,"sources":["webpack://./src/unlayer-tools/abtesting/ABTestingPanel.scss"],"names":[],"mappings":"AAAA,sBACI,oBAAqB,CACxB,sBAEG,YAAa,CACb,2BAA4B,CAC5B,qBAAsB","sourcesContent":[".ABTestingPanelToggleButton {\n    transform: scale(0.7);\n}\n.abTestingLabelWrapper {\n    display: flex;\n    place-content: space-between;\n    padding: 18px 12px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ABTestingPanelToggleButton": "rleHAVlraPw8ZvGNj1vc",
	"abTestingLabelWrapper": "KXD86TvwvEcjkNg0zcMZ"
};
export default ___CSS_LOADER_EXPORT___;
