import React from 'react';
import FormFieldContainer from '@/unlayer-tools/unlayer-form-tool/renderer/formFields/FormFieldContainer';

const HiddenField = (props) => {
    const { field, error, onValue,  className = '' } = props;

    return (
        <FormFieldContainer
            formControl={<input type='hidden' name={field.name} value={field.value || field.placeholder_text} onChange={(e) => onValue(e.target.value)} />}
            fieldType="hidden"
            error={error}
            field={field}
            className={className}
        />
    );
};

export default HiddenField;
