import React from 'react';
import { FormFieldContainer } from '@/unlayer-tools/unlayer-form-tool/renderer/formFields/FormFieldContainer';

const DateField = (props) => {
    const { field, error, onValue, className = '' } = props;

    return (
        <FormFieldContainer
            formControl={<input type="date"
                data-input-type="date"
                name={field.name}
                placeholder={field.placeholder_text}
                className="form-field"
                autoComplete="off"
                onChange={(e) => onValue(e.target.value)} />}
            fieldType="date"
            error={error}
            field={field}
            className={className}
        />
    );
};

export default DateField;
