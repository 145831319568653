import * as FullStory from '@fullstory/browser';

const ENV_STATUS = 'disabled';

/**
 * Initialize fullstory tracking setup
 * @param {Object} initObj
 * @param {String} envStatus // App environment
 */
export function fullStoryInit(initObj, envStatus) {
    if (envStatus !== ENV_STATUS) {
        FullStory.init(initObj);
    }
}

/**
 * Identify your fullstory configuration by ID
 * @param {String} id
 * @param {Object} identifyObj
 * @param {String} envStatus // App environment
 */
export function fullStoryIdentify(id, identifyObj, envStatus) {
    if (envStatus !== ENV_STATUS) {
        FullStory.identify(id, identifyObj);
    }
}

/**
 * Throw a custom event that will be tracked by fullstory
 * @param {String} message
 * @param {Object} sourceObj
 * @param {String} envStatus // App environment
 */
export function fullStoryEvent(message, sourceObj, envStatus) {
    if (envStatus !== ENV_STATUS) {
        FullStory.event(message, sourceObj);
    }
}
