import styled from 'styled-components';

import colors from './colors';

export const ModalBody = styled.div`
  font-family: inherit;
  max-width: 600px;
  
`;

export const ModalContent = styled.div`
  padding: 10px 15px;
  
  p {
    font-size: 14px;
    margin-bottom: 10px;  
  }
`;

export const ModalFooter = styled.div`
  margin-top: 15px;
  padding: 10px;
  border-top: 1px solid ${colors.lightestGray};
  text-align: right;
  overflow: hidden;
`;

export const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255,255,255,0.6);
  z-index: 1;
`;
