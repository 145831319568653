import { tinymce } from '@/unlayer-tools/unlayer';
import { executeFrameRequest } from '@/unlayer-tools/requests/frame-request';

/**
 * Adds icons/buttons to each TinyMCE component created by unlayer.  By default, the buttons will execute a cross-frame
 * request that is expected to return either undefined or an object literal:
 * {
 *     content?: string;
 *     contentHtml?: string;
 * }
 *
 * If the object literal is returned, then the TinyMCE editor will append that value to the undo stack and update the widget
 *
 * @param buttons A dictionary of button definitions
 * @param icons A dictionary of icons: the value is an svg element
 */
export function integrateWithTinyMCE({ buttons = {}, icons = {} }) {
    tinymce.on('AddEditor', function ({ editor }) {
        // Add icons
        for (const [iconName, iconData] of Object.entries(icons)) {
            editor.ui.registry.addIcon(iconName, iconData);
        }

        // Add buttons, and also add to the toolbar
        for (const [buttonKey, button] of Object.entries(buttons)) {
            const { options = {}, onAction } = button;

            editor.ui.registry.addButton(buttonKey, {
                ...options,
                onAction: async () => {
                    let editorElm = editor.targetElm.closest('.content-editor-container');
                    const contentId = editorElm?.id;
                    const contentTypes = Array.from(editorElm?.classList ?? [])?.filter((className) => className.startsWith('u_'));

                    const currentSelection = editor.selection;
                    const existingText = editor.selection.getContent({ format: 'text' });

                    // Hides the toolbar that's hovering
                    editor.fire('contexttoolbar-hide');

                    const existingHtml = editor.selection.getContent({ format: 'html' });

                    const actionParams = {
                        buttonKey,
                        contentId,
                        contentTypes,
                        editorContent: editor.getContent(),
                        editorSelectionText: existingText,
                        editorSelectionHtml: existingHtml,
                    };

                    const result = await (onAction
                        ? onAction('keapUnlayerTinyButtonClicked', actionParams, editor)
                        : executeFrameRequest('keapUnlayerTinyButtonClicked', actionParams));

                    const { contentHtml } = result ?? {};

                    let newContent = contentHtml;

                    if (newContent) {
                        // Ensures that the user can cmd-Z to undo this operation
                        editor.undoManager.add();

                        if (existingText?.length > 0) {
                            currentSelection.setContent(newContent);
                        } else {
                            editor.setContent(newContent);
                        }
                        editor.focus();
                    }

                },
            });

            // Add the button to the end of the top toolbar
            let [first = ''] = editor.settings.toolbar;

            if (!first.includes(buttonKey)) {
                first = `${first}  | ${buttonKey}`;
            }
            editor.settings.toolbar[0] = first;
        }
    });
}

