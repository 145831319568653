import React, { useState, useEffect } from 'react';
import { Input, Label } from 'reactstrap';
import { UnlayerFormField } from '@/shared/components/UnlayerFormField';
import style from '@/unlayer-tools/custom-tools/appointment-tool/calendly/calendly.scss';
import ToggleSwitch from '../../../../shared/components/ToogleSwitch/ToogleSwitch';
import { intl } from '@/shared/intl';

export const CalendlyLinkEditor = ({ value = '', updateValue, label, values: { useCalendlyLink } }) => {
    const HIDE_EVENT_TYPE_DETAILS = 'hide_event_type_details';
    const CALENDLY_DOMAIN = 'calendly.com';
    const CALENDLY_REGEX = `^https://${CALENDLY_DOMAIN}/[^/]+/[^/]+/?$`;
    const MAX_CALENDLY_LINK_LENGTH = 200;
    const ERROR_MESSAGE = intl.get('unlayer.tool.booking.calendly.link.error.message', { defaultMessage: 'Please enter a valid Calendly link' });

    /**
     * Retrieve a param from a url
     *
     * @param url the url from which a param will be extracted from
     * @param paramName the paramName to be extracted eg hide_event_type_details
     * @return 0 or 1 the value of a calendly param
     */
    const extractParamValueFromUrl = (url, paramName) => {
        const urlObj = new URL(url);
        const paramValue = urlObj.searchParams.get(paramName);

        return paramValue === '1';
    };

    /**
   * Retrieve a calendly link excluding params from a url
   *
   * @param input the url from which a valid calendly link will be extracted from eg https://calendly.com/firstname-lastname/three-try?hide_event_type_details=1&hide_gdpr_banner=1
   * @return a calendly link eg https://calendly.com/firstname-lastname/three-try
   */
    const extractCalendlyLink = (input) => {
        const urlMatch = input.match(CALENDLY_REGEX);

        if (urlMatch) {
            const url = urlMatch[0];
            const queryIndex = url.indexOf('?');

            return queryIndex !== -1 ? url.slice(0, queryIndex) : url;
        }

        return null;
    };

    const isValidCalendlyLink = (link) => {
        if (!link) {
            return false;
        }

        if (link.length > MAX_CALENDLY_LINK_LENGTH) {
            return false;
        }

        const regex = new RegExp(CALENDLY_REGEX);

        if (!regex.test(link)) {
            return false;
        }

        try {
            const url = new URL(link);

            if (url.search) {
                return false;
            }
        } catch (error) {
            return false;
        }

        return true;
    };

    const initialUrl = value ? extractCalendlyLink(value) : '';
    const initialHideEventDetails = value ? extractParamValueFromUrl(value, HIDE_EVENT_TYPE_DETAILS) : false;
    const initialValidCalendlyLink = isValidCalendlyLink(initialUrl);

    const [url, setUrl] = useState(initialUrl);
    const [hideEventDetails, setHideEventDetails] = useState(initialHideEventDetails);
    const [validCalendlyLink, setValidCalendlyLink] = useState(initialValidCalendlyLink);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        updateCalendlyLinkWithNewProperties();
    }, [url, hideEventDetails]);

    const handleCalendlyLinkChange = (e) => {
        setUrl(e.target.value.trim());
    };

    const handleToggleChange = (updateField) => {
        if (updateField === 'hideEventDetails') {
            setHideEventDetails(!hideEventDetails);
        }
    };

    const updateCalendlyLinkWithNewProperties = () => {
        const isValidLink = isValidCalendlyLink(url);

        setValidCalendlyLink(isValidLink);

        if (isValidLink) {
            const updatedLink = `${url}?${HIDE_EVENT_TYPE_DETAILS}=${hideEventDetails ? 1 : 0}`;

            setErrorMessage('');
            updateValue(updatedLink);
        } else {
            const errorMessage = url !== '' ? ERROR_MESSAGE : '';

            setErrorMessage(errorMessage);
            updateValue('');
        }
    };

    return (
        useCalendlyLink &&
          <div>
              <UnlayerFormField label={label}>
                  <Input
                      type="text"
                      value={url}
                      maxLength={100}
                      className={style.inputField}
                      onChange={handleCalendlyLinkChange}
                      onBlur={updateCalendlyLinkWithNewProperties}
                      onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                              updateCalendlyLinkWithNewProperties();
                          }
                      }}
                  />
                  {errorMessage && <div style={{ color: 'red', marginTop: '5px' }}>{errorMessage}</div>}
                  <div>
                      <Label className={style.labelText}>
                          {intl.get('unlayer.tool.booking.calendly.prop.add.calendly.description', { defaultMessage: 'Add your Calendly link here to embed your appointment on the landing page' })}
                      </Label>
                  </div>
              </UnlayerFormField>
              <hr />
              {url !== '' && validCalendlyLink && (
                  <div>
                      <div className={style.labelWrapperHorizontal}>
                          <Label className={style.labelStyle}>
                              {intl.get('unlayer.tool.booking.calendly.prop.event.details', { defaultMessage: 'Event Details' })}
                          </Label>
                          <ToggleSwitch
                              disabled={!validCalendlyLink}
                              className={style.toggleButton}
                              checked={!hideEventDetails && url !== ''}
                              onChange={() => handleToggleChange('hideEventDetails')}
                          />
                      </div>
                  </div>
              )}
          </div>
    );
};

export const CalendlyPropEditor = {
    name: 'calendly',
    Widget: CalendlyLinkEditor,
};
