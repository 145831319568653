import React from 'react';
import FormFieldContainer from '@/unlayer-tools/unlayer-form-tool/renderer/formFields/FormFieldContainer';
import { getTagsFromVirtualField } from './utils';

const DropdownField = (props) => {
    const { field, error, onValue, className = '' } = props;

    const { placeholder_text: placeholder } = field;

    // This function is used to extract the tag ids from the virtual field if it exists.
    const tagIdsForOptions = getTagsFromVirtualField(field);

    // {NOTE}: This component also includes the logic for virtual fields.
    return (
        <FormFieldContainer
            formControl={
                <select name={field.name} className={'form-field'} onChange={(e) => onValue(e.target.value)}>
                    {(!field.options || placeholder) && (
                        <option className='dropdown-placeholder' value={''}>{placeholder}</option>
                    )}

                    {field.options && field.options.split('\n').map((option, index) => {
                        // Get tag IDs for the current option if it's a virtual field.
                        // If it's a virtual field. the options are set to the tag values.
                        // if it's a normal field, it sets the default value as the option value.
                        const tagIds = tagIdsForOptions && tagIdsForOptions[index] ? tagIdsForOptions[index].join(',') : '';

                        return <option key={`option-${index}`} data-tag-id={tagIds} value={tagIds ? tagIds : option}>{option}</option>;
                    })}
                </select>}
            fieldType="dropdown"
            error={error}
            field={field}
            className={className}
        />
    );
};

export default DropdownField;
