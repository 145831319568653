import React from 'react';

const Buttons = (props) => {
    const {
        buttonClassName,
        buttonText,
        handleSubmit,
    } = props;

    return (
        <div className={'button-container'} onClick={handleSubmit}>
            <input type="submit" className={buttonClassName} value={buttonText} />

            <div className="spinner-container">
                <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            </div>
        </div>
    );
};

export default Buttons;
