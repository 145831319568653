import React from 'react';
import { getFunnelFormCount } from '@/unlayer-tools/unlayer-form-tool/requests/get-funnel-form-count';
import { Input } from 'reactstrap';
import { UnlayerFormField } from '@/shared/components/UnlayerFormField';
import { intl } from '@/shared/intl';
import style from '../styles/FormEditorStyle.scss';

const MAX_NAME_LENGTH = 40;

export class FormNameEditor extends React.Component {
    constructor(props) {
        super(props);
        const { value = '' } = this.props;

        this.state = {
            disabled: value == null,
            value,
            errorMessage: '',
        };
    }

    async componentDidMount() {
        const { updateValue, value, values: { keapForm: { id } = {} } = {} } = this.props;

        if (id) {
            this.setState({
                value,
                disabled: false,
            }, () => updateValue(value));
        } else {
            if (!value) {
                let newFormName = 'Lead capture form';

                try {
                    const { totalFormCount = 0, prefix = '' } = await getFunnelFormCount();

                    if (prefix) {
                        newFormName = prefix;

                        if (totalFormCount > 0) {
                            newFormName += ` ${totalFormCount + 1}`;
                        }
                    }
                } finally {
                    this.setState({
                        value: newFormName,
                        disabled: false,
                    });
                    updateValue(newFormName);
                }
            }
        }
    }

    handleInputChange = (event) => {
        const { value } = event.target;
        const { updateValue } = this.props;
        const messageValue = intl.get('tool.form.fields.name.error.maxLength', {
            defaultMessage: 'Maximum character limit of 40 exceeded',
        });

        this.setState({
            value: value,
            errorMessage: value.length >= 40 ? messageValue : '',
        }, () => updateValue(this.state.value));
    }

    handleBlur = () => {
        const { value } = this.state;
        const { updateValue } = this.props;

        this.setState({
            value: `${value} `,
        }, () => updateValue(this.state.value));
    }

    render() {
        const { value, disabled, errorMessage } = this.state;

        return (
            <UnlayerFormField label={this.props.label}>
                <Input
                    type="text"
                    value={value}
                    maxLength={MAX_NAME_LENGTH}
                    disabled={disabled === true}
                    onChange={this.handleInputChange}
                    onBlur={this.handleBlur}
                    className={style.inputField}
                />
                {errorMessage && <div style={{ color: 'red', marginTop: '5px' }}>{errorMessage}</div>}
            </UnlayerFormField>
        );
    }
}
