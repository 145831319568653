import React from 'react';
import Select from 'react-select';
import { getSelectOptions } from '@/unlayer-tools/requests/get-select-options';
import { WithIntl } from '@/shared/intl/intl-component';
import { intl } from '@/shared/intl';
import { Label } from 'reactstrap';
import style from './KeapSelectEditor.scss';

/**
 * A select widget that can be used within unlayer tools to fetch data from the keap web frame.  By default,
 * this select uses the name of the tool property as the name of the cross-frame call, by the convention
 * `get[property_name]Options`.  So, within keap-web, you would add a message listener.
 *
 * See {@link KeapCheckoutFormViewer} for an example
 */
export class KeapSelectEditor extends React.Component {
    constructor(props = {}) {
        super(props);

        const { name, data: { options, optionName } = {} } = this.props;

        const optName = optionName ?? name;

        if (options) {
            this.state = { options, optionName: optName };
        } else {
            this.state = { optionName: optName };
            getSelectOptions(optName).then((options)=> {
                if (options) {
                    this.setState({ options, optionName: optName });
                }
            }).catch((error)=> {
                console.warn(`Error loading ${optName} options`, error);
            });
        }
    }

    render() {

        const { values = {} } = this.props;
        const { useCalendlyLink = false } = values;
        // isSelectable is specifically used for enabling and disabling Keap appointments
        const isSelectable = !!useCalendlyLink;
        const { updateValue, value, label } = this.props;
        const { optionName, options = [] } = this.state ?? {};
        const placeholderKey = `placeholder.${optionName ?? 'default'}`;

        return (
            <WithIntl>
                <Label className={style.labelStyle}>
                    {label}
                </Label>
                <Select className={`${style.labelStyle}${style.selectFieldStyle}` }
                    key={`option-${optionName}`} placeholder={intl.get(placeholderKey)}
                    value={value}
                    isSearchable={true}
                    options={options}
                    onChange={updateValue}
                    isDisabled={isSelectable} />
            </WithIntl>);
    }
}

export const KeapSelectPropEditor = {
    name: 'keap#select',
    Widget: KeapSelectEditor,
};
