import React from 'react';
import FormFieldContainer from '@/unlayer-tools/unlayer-form-tool/renderer/formFields/FormFieldContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { getTagsFromVirtualField } from './utils';

const CheckboxField = (props) => {

    const { field, error, onValue, className = '' } = props;

    // This function is used to extract the tag ids from the virtual field if it exists.
    const tagIdsForOptions = getTagsFromVirtualField(field);

    // {NOTE}: This component also includes the logic for virtual fields.
    return (
        <FormFieldContainer
            formControl={
                <div>
                    {field.options && field.options.split('\n').map((option, index) => {
                        // Get tag IDs for the current option if it's a virtual field.
                        // If it's a virtual field. the options are set to the tag values.
                        // if it's a normal field, it sets the default value as the option value.
                        const tagIds = tagIdsForOptions && tagIdsForOptions[index] ? tagIdsForOptions[index].join(',') : '';

                        return (
                            <label key={`option-${index}`} className="checkbox">
                                <div className="checkbox-container">
                                    <input type='checkbox' name={field.name} data-tag-id={tagIds} value={tagIds ? tagIds : option} onChange={(e) => onValue(e.target.value, e.target.checked)} />{' '}
                                    <div className="box">
                                        <FontAwesomeIcon icon={faCheck} className="box-icon" style={{ padding: '2px' }}/>
                                    </div>
                                </div>
                                <div className="label-container" dangerouslySetInnerHTML={{ __html: option }} />
                            </label>
                        );
                    })}
                </div>
            }
            fieldType="checkbox"
            error={error}
            field={field}
            className={className}
        />
    );

};

export default CheckboxField;
