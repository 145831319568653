// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ee7EB4AC86Zrc6dFvqDB{font-size:12px;font-weight:500;line-height:24px;color:#515151;font-family:inherit}.uqM6mY2pDyaGPP8sPJXQ{background-color:#fff}\n", "",{"version":3,"sources":["webpack://./src/unlayer-tools/editors/KeapSelectEditor.scss"],"names":[],"mappings":"AAAA,sBACI,cAAe,CACf,eAAgB,CAChB,gBAAiB,CACjB,aAAsB,CACtB,mBAAoB,CACvB,sBAGG,qBAAsB","sourcesContent":[".labelStyle {\n    font-size: 12px;\n    font-weight: 500;\n    line-height: 24px;\n    color: rgb(81, 81, 81);\n    font-family: inherit;\n}\n\n.selectFieldStyle {\n    background-color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelStyle": "Ee7EB4AC86Zrc6dFvqDB",
	"selectFieldStyle": "uqM6mY2pDyaGPP8sPJXQ"
};
export default ___CSS_LOADER_EXPORT___;
