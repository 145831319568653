import { executeFrameRequest } from '@/unlayer-tools/requests/frame-request';

/**
 * Gets the total count of forms for the entire funnel.  This is used to auto-name forms when they are dragged out.
 *
 * @see {FormNameEditor}
 * @return {Promise<FunnelFormCountResponse>}
 */
export async function getFunnelFormCount() {
    return executeFrameRequest('getFunnelFormCount');
}

export async function getFormById(formId) {
    return executeFrameRequest('getFormById', { formId });
}

/**
 * @typedef FunnelFormCountResponse
 *
 * @property {number} totalFormCount The total count of forms
 * @property {string} prefix The i18n prefix to be applied to the form name
 */

