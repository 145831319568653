/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { EnvironmentKind, KeapEnvConfig } from '@/shared/keap-env-config';
import { buttonToolOpts } from './unlayer-button-tool/button_tool.constants';
import { appointmentToolOpts } from './unlayer-button-tool/appointment_tool.constants';

export function KeapAppointmentViewer(props = {}) {
    const { values = {} } = props;

    const { color, backgroundColor, hoverColor, hoverBackgroundColor } = values.buttonColors;
    const calculatedButtonWidth = (values.buttonWidth.autoWidth === true) ? 'fit-content' : values.buttonWidth.width;

    const bookingLink = values.appointmentAction;

    let url = `${KeapEnvConfig[EnvironmentKind[values.data.environment]].EMBED_FORM_URL}/booking/${bookingLink}`;

    if (!bookingLink) {
        url = 'https://www.keap.com/404';
    }

    return (
        <div
            className="booking-outer-container"
            // eslint-disable-next-line react/no-unknown-property
            align={values.buttonAlign}
        >
            <table
                className="booking-inner-container"
                style={{
                    width: calculatedButtonWidth,
                    margin: values.buttonMargin,
                }}
            >
                <tr>
                    <td
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        <a
                            href={url}
                            target='_blank'
                            rel="noopener noreferrer"
                            style={{
                                display: 'inline-block',
                                fontSize: values.buttonFontSize,
                                fontFamily: values.buttonFontFamily.value,
                                textDecoration: 'none',
                                lineHeight: values.buttonLineHeight,
                                color,
                                hoverColor,
                                backgroundColor,
                                hoverBackgroundColor,
                                width: '100%',
                                padding: values.buttonPadding,
                                ...values.buttonBorder,
                                borderRadius: values.buttonBorderRadius,
                                wordWrap: 'break-word',
                                boxSizing: 'border-box',
                            }}
                        >
                            { values.buttonText }
                        </a>
                    </td>
                </tr>
            </table>
        </div>
    );
}

export const KeapEmailAppointmentTool = () => ({
    name: 'unlayerAppointmentTool',
    label: 'Appointments',
    icon: `<svg width="33" height="33" viewBox="0 0 37 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
    <path d="M32.2495 40H4.47899C2.01405 40 0.00708008 37.9929 0.00708008 35.528V7.75755C0.00708008 5.2926 2.01405 3.2854 4.47899 3.2854H32.2495C34.7182 3.2854 36.7215 5.2926 36.7215 7.75755V35.528C36.7251 37.9966 34.7182 40 32.2495 40ZM4.47899 5.11305C3.02125 5.11305 1.83395 6.2998 1.83395 7.75755V35.528C1.83395 36.9857 3.02125 38.1724 4.47899 38.1724H32.2495C33.7073 38.1724 34.8973 36.9857 34.8973 35.528V7.75755C34.8973 6.2998 33.7109 5.11305 32.2495 5.11305H4.47899Z" fill="#505659"/>
    <path d="M10.5981 8.03639C10.0927 8.03639 9.68604 7.62619 9.68604 7.12439V0.912467C9.68604 0.407018 10.0963 0.000488281 10.5981 0.000488281C11.0999 0.000488281 11.5138 0.410678 11.5138 0.912467V7.12439C11.5101 7.62619 11.0999 8.03639 10.5981 8.03639Z" fill="#505659"/>
    <path d="M25.9894 8.03589C25.4839 8.03589 25.0737 7.62569 25.0737 7.12394V0.911979C25.0737 0.406529 25.4839 0 25.9894 0C26.4948 0 26.9015 0.410194 26.9015 0.911979V7.12394C26.9051 7.62569 26.4948 8.03589 25.9894 8.03589Z" fill="#505659"/>
    <path d="M35.8068 13.6097H0.915669C0.410219 13.6097 0 13.1995 0 12.6977C0 12.1959 0.410219 11.7856 0.915669 11.7856H35.8068C36.3122 11.7856 36.7224 12.1959 36.7224 12.6977C36.7224 13.1995 36.3122 13.6097 35.8068 13.6097Z" fill="#505659"/>
    </g>
    <defs>
    <clipPath id="clip0">
    <rect width="37" height="40" fill="white"/>
    </clipPath>
    </defs>
    </svg>`,
    supportedDisplayModes: ['email'],
    options: {
        ...appointmentToolOpts(),
        ...buttonToolOpts(),
    },
    values: {},
    renderer: {
        Viewer: KeapAppointmentViewer,
        exporters: {
            email(values) {
                const props = { values, displayMode: 'email' };

                return ReactDOMServer.renderToStaticMarkup(<KeapAppointmentViewer {...props} />);
            },
        },
    },
});
