import React from 'react';
import ReactDOMServer from 'react-dom/server';
import UnlayerFormTool from '../../unlayer-form-tool/renderer';
import { faWpforms } from '@fortawesome/free-brands-svg-icons/faWpforms';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { formToolOpts } from '../../unlayer-form-tool/form_tool.constants';
import { v4 } from 'uuid';
import { KeapFieldsEditor } from '../../unlayer-form-tool/editors/form/FormFieldsEditor';
import { intl, isAddFormIdEnabled } from '@/shared/intl';
import { FormNameEditor } from '@/unlayer-tools/unlayer-form-tool/editors/form/FormNameEditor';

const { Viewer: UnlayerForm, head: { css: UnlayerFormCss, js: UnlayerFormJs } } = UnlayerFormTool;

/**
 * The React render function for keap-form.  This tool wraps the default unlayer form tool with a special html
 * tag that can be recognized and enhanced.
 *
 * @see ./src/unlayer-form-tool/renderer/index.js
 * @param props
 * @return {JSX.Element}
 */
export function KeapFormViewer(props = {}) {
    const thankYouPageUrl = props.marketingPages && (props.marketingPages[1] ?? props.marketingPages[0])?.value?.pageUrl || '';

    if (isAddFormIdEnabled()) {
        const {
            values: {
                buttonAction: { url, target } = {},
                keapForm: { slug = '', id = '' } = {},
                tagApply = [],
            } = {},
            urlParamsEnabled = false,
        } = props;
        const fieldWrapper = (html, field) => {
            return <div data-keap-field-name={field.name} data-keap-is-required={field.required === true}>{html}</div>;
        };

        return (
            <keap-form
                slug={slug}
                form-id={id}
                success-target={target}
                success-redirect={url ?? thankYouPageUrl}
                url-params-enabled={urlParamsEnabled}
            >
                <form><UnlayerForm {...props} field-wrapper={fieldWrapper} />
                    <div
                        className="field-container"
                        data-name="standard.tag"
                        data-type="text"
                        data-label="Apply tags"
                        data-value={tagApply.map((tag) => tag.value).join(',')}
                    >
                        <input
                            className="form-field"
                            type='hidden'
                            name='apply-tags'
                        />
                    </div>
                </form>
            </keap-form>
        );
    } else {
        const { values: { buttonAction: { url, target } = {}, keapForm: { slug = '' } = {} } = {} } = props;

        const fieldWrapper = (html, field) => {
            return <div data-keap-field-name={field.name} data-keap-is-required={field.required === true}>{html}</div>;
        };

        return (
            <keap-form slug={slug} success-target={target} success-redirect={url ?? thankYouPageUrl}>
                <form><UnlayerForm {...props} field-wrapper={fieldWrapper} /></form>
            </keap-form>
        );
    }
}

/**
 * Unlayer tool definition for keap forms.
 *
 * This can be passed directly to `unlayer.registerTool`
 */
export const KeapFormTool = (marketingPages, tags, isFormTagCreationEnabled, urlParamsEnabled) => ({
    name: 'keap#form',
    label: intl.get('tool.form.label', { defaultMessage: 'Form' }),
    options: { ...formToolOpts({ marketingPages: marketingPages || [], tags: tags || [], isFormTagCreationEnabled }) },
    icon: icon(faWpforms, { classes: 'fa-3x' }).html,
    supportedDisplayModes: ['web', 'popup'],
    values: {
        fieldWidth: '100%',
    },
    transformer: (values) => {
        const { keapForm = {}, fields = [] } = values;

        if (!keapForm.slug) {
            keapForm.slug = v4().toString();
        }

        // Transform the values here
        return {
            ...values,
            marketingPages,
            fields,
            keapForm,
        };
    },

    renderer: {
        Viewer: KeapFormViewer,
        exporters: {
            web(values) {
                const props = { values, marketingPages, urlParamsEnabled, displayMode: 'web' };

                return ReactDOMServer.renderToStaticMarkup(<KeapFormViewer {...props} />);
            },
            popup(values) {
                const props = { values, marketingPages, urlParamsEnabled, displayMode: 'popup' };

                return ReactDOMServer.renderToStaticMarkup(<KeapFormViewer {...props} />);
            },
        },
        head: {
            css(values, bodyValues) {
                return UnlayerFormCss(values, bodyValues);
            },
            js() {
                return UnlayerFormJs();
            },
        },
    },
});

export const KeapFieldsPropEditor = {
    name: 'keap#fields',
    Widget: KeapFieldsEditor,
};


export const KeapFormNamePropEditor = {
    name: 'keap#formName',
    Widget: FormNameEditor,
};
