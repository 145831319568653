import { integrateWithTinyMCE } from '@/unlayer-tools/tinymce/integrateTinyMCE';

/**
 * Integrates content assistant with the builder, which adds a wand icon to each TinyMCE instance that shows the Content Assistant popover in keap.
 */
export function registerContentAssistantFeature() {
    integrateWithTinyMCE({
        icons: {
            wand:
                `<svg height="24" width="24"  xmlns="http://www.w3.org/2000/svg">
                    <path class="st0" d="M0,8c0,0.5,0.4,0.9,0.9,0.9h1.8c0.5,0,0.9-0.4,0.9-0.9l0,0c0-0.5-0.4-0.9-0.9-0.9H0.9C0.4,7.1,0,7.5,0,8L0,8z
                         M2.3,12.4c-0.3,0.3-0.3,0.9,0,1.3l0,0c0.3,0.3,0.9,0.3,1.3,0l1.3-1.3c0.3-0.3,0.3-0.9,0-1.3l0,0c-0.3-0.3-0.9-0.3-1.3,0L2.3,12.4z
                         M2.3,2.3C2,2.7,2,3.3,2.3,3.6l1.3,1.3c0.3,0.3,0.9,0.3,1.3,0l0,0c0.3-0.3,0.3-0.9,0-1.3L3.6,2.3C3.3,2,2.7,2,2.3,2.3L2.3,2.3z
                         M5.3,8.1c0,0.7,0.3,1.4,0.8,2l13.1,13.1c1.1,1.1,2.8,1.1,3.9,0c1.1-1.1,1.1-2.8,0-3.9L10.1,6.1C9,5.1,7.2,5.1,6.1,6.1
                        C5.6,6.7,5.3,7.4,5.3,8.1z M7.1,8.1c0-0.3,0.1-0.5,0.3-0.7c0.4-0.4,1-0.4,1.4,0l2.4,2.4l-1.4,1.4L7.4,8.8C7.2,8.6,7.1,8.4,7.1,8.1z
                         M7.1,2.7c0,0.5,0.4,0.9,0.9,0.9l0,0c0.5,0,0.9-0.4,0.9-0.9V0.9C8.9,0.4,8.5,0,8,0l0,0C7.5,0,7.1,0.4,7.1,0.9V2.7z M11.1,3.6
                        c-0.3,0.3-0.3,0.9,0,1.3l0,0c0.3,0.3,0.9,0.3,1.3,0l1.3-1.3c0.3-0.3,0.3-0.9,0-1.3l0,0c-0.3-0.3-0.9-0.3-1.3,0L11.1,3.6z M11,12.4
                        l1.4-1.4l9.5,9.5c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0L11,12.4z" />
                </svg>`,
        },
        buttons: {
            contentassistant: {
                options: {
                    icon: 'wand',
                },
            },
        },
    });
}
