import React from 'react';

export function Icon({ icon, width, height, ...others } = {}) {
    // eslint-disable-next-line no-unused-vars
    const [_, __, defHeight, defWidth] = icon.viewBox?.split(' ');

    return (
        <svg height={height ?? defHeight} width={width ?? defWidth}  viewBox={icon.viewBox} {...others}>
            <use xlinkHref={`#${icon.id}`} />
        </svg>
    );
}

export default Icon;
