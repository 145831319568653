// src/components/ToggleSwitch.js
import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { Label } from 'reactstrap';
import style from './ToggleSwitch.scss';

const ToggleSwitch = ({ id, checked, onChange, label, ...props }) => {
    return (
        <div className={style.toggleSwitch}>
            {label && <Label className={style.labelStyle} htmlFor={id}>{label}</Label>}
            <Switch
                id={id}
                checked={checked}
                onChange={onChange}
                offColor="#000"
                onColor="#000"
                onHandleColor="#fff"
                offHandleColor="#fff"
                {...props}
            />
        </div>
    );
};

ToggleSwitch.propTypes = {
    id: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
};

export default ToggleSwitch;
