const colors = {
    lightestGray: '#EEE',
    lighterGray: '#DEE1E2',
    lightGray: '#AAA',
    gray: '#999',
    darkGray: '#555',
    lightBlue: '#4CB9EA',
};

export default colors;
