import { intl } from '@/shared/intl';

export const formToolOpts = ({ marketingPages, tags, isFormTagCreationEnabled = false }) => {
    const formConstants = {
        default: {
            title: null,
            options: {},
            values: {
                formLayoutType: 'dynamic',
            },
        },
        fields: {
            position: 1,
            title: intl.get('tool.form.fields.group.fields.title', { defaultMessage: 'Form Name & Fields' }),
            options: {
                formName: {
                    label: intl.get('tool.form.name.label', { defaultMessage: 'Form Name' }),
                    defaultValue: '',
                    widget: 'keap#formName',
                },
                fields: {
                    label: intl.get('tool.form.fields.label', { defaultMessage: 'Form Fields' }),
                    defaultValue: [
                        {
                            name: 'standard.givenName',
                            type: 'text',
                            label: intl.get('tool.form.fields.givenName.label', { defaultMessage: 'First Name' }),
                            placeholder_text: intl.get('tool.form.fields.standard.givenName.placeholder', { defaultMessage: 'Enter your first name' }),
                            show_label: true,
                            required: true,
                            width: 2,
                        },
                        {
                            name: 'standard.emailAddress1',
                            type: 'email',
                            label: intl.get('tool.form.fields.email.label', { defaultMessage: 'Email address' }),
                            placeholder_text: intl.get('tool.form.fields.standard.email.placeholder', { defaultMessage: 'Enter your email address' }),
                            options: '',
                            required: true,
                            show_label: true,
                        },
                    ],
                    widget: 'keap#fields',
                },

                fieldBorder: {
                    label: intl.get('tool.properties.border', { defaultMessage: 'Border' }),
                    defaultValue: {
                        borderTopWidth: '1px',
                        borderTopStyle: 'solid',
                        borderTopColor: '#C0C0C0',
                        borderLeftWidth: '1px',
                        borderLeftStyle: 'solid',
                        borderLeftColor: '#C0C0C0',
                        borderRightWidth: '1px',
                        borderRightStyle: 'solid',
                        borderRightColor: '#C0C0C0',
                        borderBottomWidth: '1px',
                        borderBottomStyle: 'solid',
                        borderBottomColor: '#C0C0C0',
                    },
                    widget: 'border',
                    hidden: true,
                },
                fieldBorderRadius: {
                    label: intl.get('tool.properties.roundedBorder', { defaultMessage: 'Rounded Border' }),
                    defaultValue: '4px',
                    widget: 'border_radius',
                    hidden: true,
                },
                fieldPadding: {
                    label: intl.get('tool.properties.padding', { defaultMessage: 'Padding' }),
                    defaultValue: '14px 10px',
                    widget: 'padding',
                    hidden: true,
                },
                fieldBackgroundColor: {
                    label: intl.get('tool.properties.backgroundColor', { defaultMessage: 'Background Color' }),
                    defaultValue: '#FFFFFF',
                    widget: 'color_picker',
                    hidden: true,
                },
                fieldColor: {
                    label: intl.get('tool.properties.textColor', { defaultMessage: 'Text Color' }),
                    defaultValue: '#000',
                    widget: 'color_picker',
                    hidden: true,
                },
                fieldFontSize: {
                    label: intl.get('tool.properties.fontSize', { defaultMessage: 'Font Size' }),
                    defaultValue: '14px',
                    widget: 'px',
                    hidden: true,
                },
                fieldFontFamily: {
                    label: intl.get('tool.properties.fontFamily', { defaultMessage: 'Family Family' }),
                    defaultValue: {
                        label: 'Arial',
                        value: 'arial,helvetica,sans-serif',
                    },
                    widget: 'font_family',
                    hidden: true,
                },
            },
        },
        layout: {
            position: 2,
            title: intl.get('tool.properties.layout', { defaultMessage: 'Layout' }),
            options: {
                stackOnMobile: {
                    label: intl.get('tool.properties.stackOnMobile', { defaultMessage: 'Stack on Mobile' }),
                    widget: 'toggle',
                    defaultValue: true,
                    enabled: false,
                    supportedDisplayModes: ['web', 'popup'],
                },
                formWidth: {
                    label: intl.get('tool.properties.formWidth', { defaultMessage: 'Form Width' }),
                    defaultValue: {
                        autoWidth: false,
                        fullWidthOnMobile: false,
                        width: '100%',
                    },
                    widget: 'auto_width',
                    overrideAllowed: ['mobile'],
                },
                formAlign: {
                    label: intl.get('tool.properties.formAlignment', { defaultMessage: 'Form Alignment' }),
                    defaultValue: 'center',
                    widget: 'alignment',
                },
                fieldDistance: {
                    label: intl.get('tool.properties.spaceBetweenFields', { defaultMessage: 'Space Between Fields' }),
                    defaultValue: '8px',
                    widget: 'px',
                },
            },
        },
        labels: {
            position: 3,
            title: intl.get('tool.properties.labels', { defaultMessage: 'Labels' }),
            options: {
                labelFontSize: {
                    label: intl.get('tool.properties.fontSize', { defaultMessage: 'Font Size' }),
                    defaultValue: '14px',
                    widget: 'px',
                },
                labelFontFamily: {
                    label: intl.get('tool.properties.fontFamily', { defaultMessage: 'Font Family' }),
                    defaultValue: {
                        label: 'Arial',
                        value: 'arial,helvetica,sans-serif',
                    },
                    widget: 'font_family',
                },
                labelColor: {
                    label: intl.get('tool.properties.color', { defaultMessage: 'Color' }),
                    defaultValue: '#000000',
                    widget: 'color_picker',
                },
                labelAlign: {
                    label: intl.get('tool.properties.alignment', { defaultMessage: 'Alignment' }),
                    defaultValue: 'left',
                    widget: 'alignment',
                },
                labelPadding: {
                    label: intl.get('tool.properties.padding', { defaultMessage: 'Padding' }),
                    defaultValue: '0px 0px 8px',
                    widget: 'padding',
                    hidden: true,
                },
            },
        },
        button: {
            position: 4,
            title: intl.get('tool.properties.button', { defaultMessage: 'Button' }),
            options: {
                buttonAction: {
                    label: intl.get('tool.properties.buttonAction', { defaultMessage: 'Action' }),
                    defaultValue: {
                        name: 'marketingPage',
                        attrs: {
                            href: '{{marketingPage}}',
                            target: '_self',
                            'data-keap-link-type': 'marketingPage',
                        },
                        values: {
                            marketingPage: marketingPages && marketingPages[0]?.value?.pageUrl,
                        },
                    },
                    widget: 'link',
                },
                tagApply: {
                    defaultValue:  [],
                    data: {
                        field: {
                            label: intl.get('tool.properties.tagApply', { defaultMessage: 'Apply Tag' }),
                            show_label: true,
                            required: false,
                            className: 'field-container field-container-dynamic',
                            placeholder: intl.get('tool.properties.tagSelectPlaceholder', { defaultMessage: 'Select options' }),
                            options: tags || [],
                            isCreatable: isFormTagCreationEnabled || false,
                        },
                    },
                    widget: 'multiselectTags',
                },
                buttonText: {
                    label: intl.get('tool.properties.buttonTextLabel', { defaultMessage: 'Text' }),
                    defaultValue: intl.get('tool.properties.defaultSubmit', { defaultMessage: 'Submit' }),
                    widget: 'text',
                },
                buttonPosition: {
                    label: intl.get('tool.properties.formPosition', { defaultMessage: 'Form Position' }),
                    defaultValue: '2',
                    widget: 'rowlayout',
                    enabled: false,
                },
                buttonColors: {
                    label: intl.get('tool.properties.buttonColors', { defaultMessage: 'Color' }),
                    defaultValue: {
                        color: '#FFF',
                        backgroundColor: '#2C84D2',
                        hoverColor: '#FFF',
                        hoverBackgroundColor: '#1D5A8F',
                    },
                    widget: 'button_color',
                },
                buttonAlign: {
                    label: intl.get('tool.properties.alignment', { defaultMessage: 'Alignment' }),
                    defaultValue: 'left',
                    widget: 'alignment',
                },
                buttonWidth: {
                    label: intl.get('tool.properties.width', { defaultMessage: 'Width' }),
                    defaultValue: {
                        autoWidth: false,
                        fullWidthOnMobile: false,
                        width: '25%',
                    },
                    widget: 'auto_width',
                    overrideAllowed: ['mobile'],
                },
                buttonFontSize: {
                    label: intl.get('tool.properties.buttonColors', { defaultMessage: 'Font Size' }),
                    defaultValue: '16px',
                    widget: 'px',
                },
                buttonFontFamily: {
                    label: intl.get('tool.properties.fontFamily', { defaultMessage: 'Font Family' }),
                    defaultValue: {
                        label: 'Arial',
                        value: 'arial,helvetica,sans-serif',
                    },
                    widget: 'font_family',
                },
                buttonBorder: {
                    label: intl.get('tool.properties.border', { defaultMessage: 'Border' }),
                    defaultValue: {},
                    widget: 'border',
                    hidden: true,
                },
                buttonBorderRadius: {
                    label: intl.get('tool.properties.roundedBorder', { defaultMessage: 'Rounded Border' }),
                    defaultValue: '4px',
                    widget: 'border_radius',
                    hidden: true,
                },
                buttonPadding: {
                    label: intl.get('tool.properties.padding', { defaultMessage: 'Padding' }),
                    defaultValue: '14px 10px',
                    widget: 'padding',
                    hidden: true,
                },
                buttonMargin: {
                    label: intl.get('tool.properties.margin', { defaultMessage: 'Margin' }),
                    defaultValue: '8px 0px 0px',
                    widget: 'margin',
                    hidden: true,
                },
            },
        },
    };

    return formConstants;
};
