import React from 'react';
import HelpIcon from '@/icons/help-circle.svg';
import { FormFeedback, Input, Label } from 'reactstrap';
import { Panel, PanelHeader, PanelSection } from '@/unlayer-tools/components/pages-panel-bar';
import style from './SettingsPanel.scss';
import _debounce from 'lodash/debounce';
import { convert } from 'url-slug';
import { intl } from '@/shared/intl';
import {
    requestUpdateSiteSettings,
    requestRenameSite,
    requestUpdateSlug,
    requestHandleFaviconUpload,
} from '@/unlayer-tools/requests/pages-requests';
import ToggleSwitch from '../../../shared/components/ToogleSwitch/ToogleSwitch';
import { requestSiteSettings } from '../../requests/pages-requests';

export function createSettingsPanel(options = {}, { showPublishSettings = false, showAnalyticsSettings = true, domainSettingsUrl = '', showFaviconSettings } = {}) {
    const SettingsPanel = buildSettingsPanelComponent(options, { showPublishSettings, showAnalyticsSettings, domainSettingsUrl, showFaviconSettings });

    const SettingsPanelSettings = {
        name: 'funnel_settings',
        label: intl.get('settingsPanel.panelName', { defaultMessage: 'Settings' }),
        icon: 'fa-cog',
        // todo: Add the correct svg logo
        supportedDisplayModes: ['web'],
        renderer: {
            Panel: SettingsPanel,
        },
    };

    return SettingsPanelSettings;
}

const googleSupportLink = 'https://support.google.com/sites/answer/97459#zippy=%2Cadd-analytics-tracking';
const facebookSupportLink = 'https://www.facebook.com/business/help/952192354843755?id=1205376682832142';
const MAX_NAME_LENGTH = 40;
const MAX_SLUG_LENGTH = 40;

export function buildSettingsPanelComponent(site, { showPublishSettings, showAnalyticsSettings, domainSettingsUrl, showFaviconSettings }) {
    class SettingsPanelComponent extends React.Component {
        constructor(props, context) {
            super(props, context);
            this.fileInputRef = React.createRef();
            this.state = {
                showUrlTooltip: false,
                facebookTrackingEnabled: site.settings?.facebookTrackingEnabled,
                googleTrackingEnabled: site.settings?.googleTrackingEnabled,
                facebookTrackingId: site.settings?.facebookTrackingId,
                googleTrackingId: site.settings?.googleTrackingId,
                faviconUrl: site.settings?.faviconUrl,
                name: site.name,
                slug: site.slug,
                nameInputFocused: false,
                slugInputFocused: false,
                nameError: '',
                slugError: '',
                file: [],
                faviconUploaded: false,
                uploadedFaviconLink: null,
            };
        }

        async componentDidMount() {
            const settings = await requestSiteSettings();

            if (settings) {
                this.setState({
                    facebookTrackingEnabled: settings?.facebookTrackingEnabled,
                    googleTrackingEnabled: settings?.googleTrackingEnabled,
                    facebookTrackingId: settings?.facebookTrackingId,
                    googleTrackingId: settings?.googleTrackingId,
                    faviconUrl: settings?.faviconUrl,
                });
            }
        }

        updateSiteSettings = _debounce(async(newSettings) => requestUpdateSiteSettings(newSettings), 300);
        updateSiteName = _debounce(async (newName) => requestRenameSite(newName), 300);
        updateSiteSlug = _debounce(async (newSlug) => requestUpdateSlug(newSlug), 300);

        onUpdateSiteSettings(updateField, updateValue) {
            const newSettings = {
                facebookTrackingEnabled: this.state.facebookTrackingEnabled,
                googleTrackingEnabled: this.state.googleTrackingEnabled,
                facebookTrackingId: this.state.facebookTrackingId,
                googleTrackingId: this.state.googleTrackingId,
                faviconUrl: this.state.faviconUrl,
            };

            newSettings[updateField] = updateValue;

            if (updateField === 'faviconUrl') {
                newSettings.faviconUrl = updateValue;
            }

            if (updateField === 'googleTrackingId') {

                if (this.isGoogleTrackingIdValid(updateValue)) {
                    newSettings.googleTrackingEnabled = true;
                }
            }

            if (updateField === 'facebookTrackingId') {
                if (this.isFacebookTrackingIdValid(updateValue)) {
                    newSettings.facebookTrackingEnabled = true;
                }
            }

            this.setState({ ...newSettings });
            this.updateSiteSettings(newSettings);
        }

        isGoogleTrackingIdValid = (id) => (id ? `${id}` : '').match(/(G|UA|YT|MO)-([A-z]|\d)/g);
        isFacebookTrackingIdValid = (id) => (id ? `${id}` : '').match(/\d/g);
        setName = async (nameElement) => {
            const name = nameElement.value;

            try {
                this.setState({ name, nameError: '' });
                if (name.length > MAX_NAME_LENGTH) throw new Error('max-length');
                const result = await this.updateSiteName(name);

                if (result && result.error) throw new Error();
            } catch (error) {
                if (error && error.message === 'max-length') {
                    this.setState({ nameError: intl.get('settingsPanel.publishSettings.name.errors.maxlength', { defaultMessage: `Can't exceed ${MAX_NAME_LENGTH} characters` }) });
                } else {
                    this.setState({ nameError: intl.get('settingsPanel.publishSettings.name.errors.generic', { defaultMessage: 'Site Name failed to save' }) });
                }
            }
        }

        setSlug = async (slugElement, slugifyInline = false) => {
            const slug = slugElement.value;

            // we only want to "slugify" after the user's done typing or else they can't enter an invalid slug
            const adjustedSlug = slugifyInline
                ? convert(slug)
                : slug.replaceAll(' ', '-').replace(/[^\w-]+/g, '');

            slugElement.value = adjustedSlug;

            try {
                this.setState({ slug: adjustedSlug, slugError: '' });

                if (adjustedSlug.length > MAX_SLUG_LENGTH) throw new Error('max-length');
                const result = await this.updateSiteSlug(convert(adjustedSlug));

                if (result && result.error) throw new Error();
            } catch (error) {
                if (error && error.message === 'max-length') {
                    this.setState({ slugError: intl.get('settingsPanel.publishSettings.slug.errors.maxlength', { defaultMessage: `Can't exceed ${MAX_SLUG_LENGTH} characters` }) });
                } else {
                    this.setState({ slugError: intl.get('settingsPanel.publishSettings.slug.errors.generic', { defaultMessage: 'URL handle failed to save' }) });
                }
            }
        }

        setUrlTooltip = (showUrlTooltip) => {
            this.setState({ showUrlTooltip });
        }

        nameInputFocus = (e, nameInputFocused) => {
            this.setState({ nameInputFocused });
            if (!nameInputFocused) this.setName(e.currentTarget);
        }

        slugInputFocus = (e, slugInputFocused) => {
            this.setState({ slugInputFocused });
            if (!slugInputFocused) this.setSlug(e.currentTarget);
        }

        handleButtonClick = () => {
            this.fileInputRef.current.click();
        }

        handleFileChange = (e) => {
            const selectedFile = e.target.files[0];

            this.setState({ file: [selectedFile] }, () => {
                this.handleFaviconUpload();
            });
        }

        handleFaviconUpload = () => {
            const { file } = this.state;

            if (file) {
                this.exportFaviconUrl(file);
            }
            this.setState({ faviconUploaded : true });
        }

        async exportFaviconUrl(file) {
            const { facebookTrackingEnabled, facebookTrackingId, googleTrackingEnabled, googleTrackingId } = this.state;
            const fileUrl = await requestHandleFaviconUpload(file);

            this.setState({ faviconUrl: fileUrl.faviconUrl });
            this.setState({ uploadedFaviconLink : { url : fileUrl.faviconUrl, file: file[0] } });
            await requestUpdateSiteSettings({ faviconUrl: fileUrl.faviconUrl, facebookTrackingEnabled, facebookTrackingId, googleTrackingEnabled, googleTrackingId });
        }

        handleFaviconDeletion = async () => {
            const { facebookTrackingEnabled, facebookTrackingId, googleTrackingEnabled, googleTrackingId } = this.state;

            this.setState({ faviconUrl : '' }, () => {
                requestUpdateSiteSettings({ faviconUrl: '', facebookTrackingEnabled, facebookTrackingId, googleTrackingEnabled, googleTrackingId });
            });
            this.setState({ faviconUploaded:false });
        }

        render() {
            const { facebookTrackingEnabled, googleTrackingEnabled, facebookTrackingId, googleTrackingId } = this.state;
            const googleTrackingIdValid = this.isGoogleTrackingIdValid(this.state.googleTrackingId);
            const facebookTrackingIdValid = this.isFacebookTrackingIdValid(this.state.facebookTrackingId);
            const { faviconUploaded, uploadedFaviconLink, faviconUrl } = this.state;
            const imageSizeConverter = (fileSize) => (Math.round(fileSize / 1024));

            return (
                <div className="SettingsPanel">
                    <Panel>
                        {showFaviconSettings &&
                            (<PanelSection>
                                <PanelHeader title={intl.get('settingsPanel.showFavicon.panelName', { defaultMessage: 'Favicon' })} />
                                <div className={style.containerStyle}>
                                    <Label className={style.labelStyle}>
                                        {intl.get('settingsPanel.showFavicon.name.label', { defaultMessage: 'Favicon' })}
                                    </Label>

                                    <div>
                                        <Label className={style.labelText}>
                                            {intl.get('settingsPanel.showFavicon.text.content', { defaultMessage: 'A favicon is a small icon used on web browsers to represent a website or a web page ( Size: 32x32 pixel)' })}
                                        </Label>
                                    </div>

                                    <button onClick={this.handleButtonClick} className={style.styledButton}>
                                        {intl.get('settingsPanel.showFavicon.uploadButton.text', { defaultMessage: 'Upload image' })}
                                        <input type='file' accept='.ico, .png, .jpeg , .jpg' ref={this.fileInputRef} className={style.inputFileManager}  onChange={this.handleFileChange} />
                                    </button>

                                    {!faviconUploaded && !faviconUrl  ?  <div className={style.faviconUploadManager}>
                                        <Label className={style.labelText}>
                                            {intl.get('settingsPanel.showFavicon.uploadManager.text.content', { defaultMessage: 'Drop a new image here, or click to select files to upload' })}

                                        </Label>
                                    </div> : <> <div className={style.imageUploadManager}>
                                        <div className={style.faviconImageContainer}>
                                            { uploadedFaviconLink ?
                                                <img src={uploadedFaviconLink.url} alt='favicon-image' width="80" height="80" />
                                                : (
                                                    <div>
                                                        <img src={faviconUrl} alt='favicon-image' width="80" height="80" />
                                                    </div>
                                                )
                                            }
                                        </div>
                                        {faviconUrl && !uploadedFaviconLink ? <p className={style.uploadedFaviconDeleteButton} onClick={this.handleFaviconDeletion}>remove</p> : null}
                                        <div className={style.faviconContentText}>
                                            {uploadedFaviconLink && (
                                                <div>
                                                    <div className={style.labelText}>{uploadedFaviconLink.file.name}</div>
                                                    <div className={style.imageSizeText}>
                                                        {imageSizeConverter(uploadedFaviconLink.file.size)} KB
                                                    </div>
                                                    <p className={style.faviconDeleteButton} onClick={this.handleFaviconDeletion}>remove</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        <div className={style.imageUrlManager}>
                                            <Label className={style.labelStyle}>{intl.get('settingsPanel.showFavicon.faviconLink.text', { defaultMessage: 'Image URL' })}</Label>
                                            <Label className={style.labelText}>{intl.get('settingsPanel.showFavicon.faviconLink.text.size', { defaultMessage: '32X32' })}</Label>
                                        </div>
                                        <Input className={style.inputField} value={faviconUrl} placeholder={intl.get('settingsPanel.showFavicon.uploadManager.input.placeholderText', { defaultMessage: 'Web link of the file' })} />
                                    </div></>}
                                </div>
                            </PanelSection>)
                        }

                        {showPublishSettings && (
                            <PanelSection>
                                <PanelHeader title={intl.get('settingsPanel.publishSettings.panelName', { defaultMessage: 'Publish Settings' })} />
                                <div className={style.containerStyle}>
                                    <Label className={`${style.labelStyle} ${this.state.nameError ? style.error : ''}`}>
                                        {intl.get('settingsPanel.publishSettings.name.label', { defaultMessage: 'Page Title' })}
                                    </Label>
                                    <div className={style.feedbackContainer}>
                                        <Input
                                            invalid={this.state.nameError}
                                            className={style.inputField}
                                            defaultValue={this.state.name}
                                            onFocus={(e) => this.nameInputFocus(e, true)}
                                            onBlur={(e) => this.nameInputFocus(e, false)}
                                            onChange={(e) => this.setName(e.currentTarget)}
                                        />
                                        <FormFeedback invalid>{this.state.nameError}</FormFeedback>
                                        {this.state.nameInputFocused && (
                                            <div className={`${style.inputCountDisplay} ${this.state.nameError ? style.error : ''}`}>
                                                {this.state.name.length}/{MAX_NAME_LENGTH}
                                            </div>
                                        )}
                                    </div>
                                    <Label className={`${style.labelStyle} ${this.state.slugError ? style.error : ''}`}>
                                        {intl.get('settingsPanel.publishSettings.urlHandle.label', { defaultMessage: 'URL handle' })}
                                    </Label>
                                    <Label className={style.labelStyle}>
                                        <div onMouseEnter={() => this.setUrlTooltip(true)} onMouseLeave={() => this.setUrlTooltip(false)}>
                                            <svg className={this.state.slugError && style.error} height={14} width={14} viewBox={HelpIcon.viewBox} >
                                                <use xlinkHref={`#${HelpIcon.id}`} />
                                            </svg>
                                        </div>
                                    </Label>
                                    {this.state.showUrlTooltip && <div className={style.urlTooltip}>
                                        {intl.get('settingsPanel.publishSettings.urlhandle.tooltip', { defaultMessage: 'A custom URL is created based on the name you gave your landing page. When you change the URL handle, it will automatically change the ending URL.' })}
                                    </div>}
                                    <div className={style.feedbackContainer}>
                                        <Input
                                            invalid={this.state.slugError}
                                            className={style.inputField}
                                            defaultValue={this.state.slug}
                                            onFocus={(e) => this.slugInputFocus(e, true)}
                                            onBlur={(e) => this.slugInputFocus(e, false)}
                                            onChange={(e) => this.setSlug(e.currentTarget)}
                                        />

                                        <FormFeedback invalid>{this.state.slugError}</FormFeedback>
                                        {this.state.slugInputFocused && (
                                            <div className={`${style.inputCountDisplay} ${this.state.slugError ? style.error : ''}`}>
                                                {this.state.slug.length}/{MAX_SLUG_LENGTH}
                                            </div>
                                        )}
                                    </div>
                                    <div style={{ padding: '12px' }} />
                                    {domainSettingsUrl && (
                                        <a className={style.linkStyle} rel="noreferrer" target="_blank" href={domainSettingsUrl}>
                                            {intl.get('settingsPanel.publishSettings.domain.label', { defaultMessage: 'View Landing Page Domain' })}
                                        </a>
                                    )}
                                </div>
                            </PanelSection>
                        )}
                        {showAnalyticsSettings && (
                            <PanelSection>
                                <PanelHeader title={intl.get('settingsPanel.analytics.panelName', { defaultMessage: 'Analytics Settings' })} />
                                <div className={style.containerStyle}>
                                    <div className={style.columnStyle}>
                                        <div className={style.columnStyle}>
                                            <Label className={style.labelStyle}>
                                                {intl.get('settingsPanel.analytics.google.label', { defaultMessage: 'Google Analytics Tracking ID' })}
                                            </Label>
                                            <a className={style.linkStyle} rel="noreferrer" target="_blank" href={googleSupportLink}>
                                                {intl.get('settingsPanel.help.label', { defaultMessage: 'How do I get this?' })}
                                            </a>
                                        </div>
                                        <Input
                                            className={style.inputField}
                                            name="googleTrackingId"
                                            value={googleTrackingId}
                                            invalid={googleTrackingId && googleTrackingEnabled && !googleTrackingIdValid}
                                            placeholder={intl.get('settingsPanel.analytics.google.placeholder', { defaultMessage: 'Google Analytics Tracking ID' })}
                                            defaultChecked={false}
                                            onChange={(e) => this.onUpdateSiteSettings('googleTrackingId', e.target.value)}
                                        />
                                        {googleTrackingId && googleTrackingEnabled && !googleTrackingIdValid && (
                                            <FormFeedback>
                                                {intl.get('settingsPanel.analytics.google.validation', { defaultMessage: 'Use a valid ID, e.g., UA-12345678-1' })}
                                            </FormFeedback>
                                        )}
                                        <div>
                                            <ToggleSwitch
                                                disabled={!googleTrackingIdValid}
                                                className={style.toggleButton}
                                                checked={googleTrackingIdValid && googleTrackingEnabled}
                                                onChange={() => this.onUpdateSiteSettings('googleTrackingEnabled', !googleTrackingEnabled)}
                                                label= {intl.get('settingsPanel.analytics.google.toggle.label', { defaultMessage: 'Enable Google Analytics' })}
                                            />
                                        </div>
                                    </div>

                                    <div className={style.columnStyle}>
                                        <div className={style.columnStyle}>
                                            <Label className={style.labelStyle}>
                                                {intl.get('settingsPanel.analytics.facebook.label', { defaultMessage: 'Facebook Pixel ID' })}
                                            </Label>
                                            <a className={style.linkStyle} rel="noreferrer" target="_blank" href={facebookSupportLink}>
                                                {intl.get('settingsPanel.help.label', { defaultMessage: 'How do I get this?' })}
                                            </a>
                                        </div>
                                        <Input
                                            className={style.inputField}
                                            name="facebookTrackingId"
                                            value={facebookTrackingId}
                                            invalid={facebookTrackingId && facebookTrackingEnabled && !facebookTrackingIdValid}
                                            placeholder={intl.get('settingsPanel.analytics.facebook.placeholder', { defaultMessage: 'Facebook Pixel ID' })}
                                            defaultChecked={false}
                                            onChange={(e) => this.onUpdateSiteSettings('facebookTrackingId', e.target.value)}
                                        />
                                        {facebookTrackingId && facebookTrackingEnabled && !facebookTrackingIdValid && (
                                            <FormFeedback>
                                                {intl.get('settingsPanel.analytics.facebook.validation', { defaultMessage: 'Use a valid ID, e.g., ID12345678' })}
                                            </FormFeedback>
                                        )}
                                        <div>
                                            <ToggleSwitch
                                                disabled={!facebookTrackingIdValid}
                                                className={style.toggleButton}
                                                checked={facebookTrackingIdValid && facebookTrackingEnabled}
                                                onChange={() => this.onUpdateSiteSettings('facebookTrackingEnabled', !facebookTrackingEnabled)}
                                                label= {intl.get('settingsPanel.analytics.facebook.toggle.label', { defaultMessage: 'Enable Facebook Analytics' })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </PanelSection>
                        )}
                    </Panel>
                </div>
            );
        }
    }

    return SettingsPanelComponent;
}
