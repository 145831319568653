import React from 'react';
import FormFieldContainer from '@/unlayer-tools/unlayer-form-tool/renderer/formFields/FormFieldContainer';

const TextAreaField = (props) => {
    const { field, error, onValue,  className = '' } = props;

    return (
        <FormFieldContainer
            formControl={<textarea
                name={field.name}
                placeholder={field.placeholder_text}
                className={'form-field'}
                onChange={(e) => onValue(e.target.value)} />}
            fieldType="text"
            error={error}
            field={field}
            className={className}
        />
    );

};

export default TextAreaField;
