import React from 'react';
import FormFieldContainer from '@/unlayer-tools/unlayer-form-tool/renderer/formFields/FormFieldContainer';

const NumberField = (props) => {
    const { field, error, onValue, className = '' } = props;

    return (
        <FormFieldContainer
            formControl={<input
                type='number'
                name={field.name}
                placeholder={field.placeholder_text}
                className={'form-field'}
                onChange={(e) => onValue(e.target.value)} />}
            fieldType="number"
            error={error}
            field={field}
            className={className}
        />
    );

};

export default NumberField;
