import React from 'react';
import FormFieldContainer from '@/unlayer-tools/unlayer-form-tool/renderer/formFields/FormFieldContainer';

const EmailField = (props) => {
    const { field, error, onValue,  className = '' } = props;

    return (
        <FormFieldContainer
            formControl={
                <input
                    type='email'
                    name={field.name}
                    placeholder={field.placeholder_text}
                    className={'form-field'}
                    onChange={(e) => onValue(e.target.value)} />}
            fieldType="email"
            error={error}
            field={field}
            className={className}
        />
    );
};

export default EmailField;
