import { intl } from '@/shared/intl';
import { unlayer } from '@/unlayer-tools/unlayer';
import { getSelectOptions } from '@/unlayer-tools/requests/get-select-options';

/**
 * Contains all registered customLinkTypes, including any cross-frame request configuration.
 */
let customLinkTypeProviders = {};

/**
 * Contains the latest calculated values for all customLinkTypes.
 *
 * The `values` of this object is what you pass to unlayer
 */
let currentCustomLinkTypes = {};

export const KeapCustomLinkTypes = {
    marketingPage: 'marketingPage',
    marketingSite: 'marketingSite',
    keapCheckoutForm: 'checkoutForm',
    keapAppointmentType: 'appointmentType',
};

/**
 * Registers a single customLinkType.
 *
 * @param linkType The key used to reference this linkType
 * @param config What is passed to unlayer as configuration for this linkType.
 * @param options Any cross-frame option loaders.  Optional
 */
export function registerLinkType(linkType, { config, options }) {
    setLinkType(linkType, config);
    customLinkTypeProviders[linkType] = {
        name: linkType,
        config,
        options,
    };

    loadLookupOptions(linkType, options);
}


/**
 * Registers a link type that leverages the same cross-frame lookups as the `keap-select` React component.  Whatever
 * the value of `linkType` is, there should be a corresponding cross-frame handler named get${linkType}Options that
 * returns data in the options format, eg:
 * ```
 * return [
 *   {
 *      label: 'My Label',
 *      value: {
 *          ...data,
 *      },
 *   }
 * ];
 * ```
 *
 * If some massaging of data is needed, then the `mapper` function can be provided
 *
 * @param linkType
 * @param options
 * @param mapper A function to map each returned result.
 */
export function registerLookupLinkType({ linkType, options, mapper }) {
    mapper = mapper ?? ((i) => i);
    registerLinkType(linkType, {
        config: {
            label: intl.get(`customLinks.${linkType}.label`, { defaultMessage: `Go to ${linkType.replace(/([A-Z])/g, ' $1').trim().toLocaleLowerCase()}` }),
            attrs: {
                href: `{{${linkType}}}`,
                target: '_self',
                'data-keap-link-type': linkType,
            },
            fields: [
                LinkTypeSelectField({
                    linkType,
                    options: options?.map(mapper),
                }),
            ],
        },
        options: options != null ? null : {
            [linkType]: async () => {
                const options = await getSelectOptions(linkType);

                return options.map(mapper);
            },
        },
    });
}

/**
 * Triggers the cross-frame request for a single customLinkType.  If the specified `linkType` does not have a
 * cross frame loader, then this function is a no-op.
 *
 * When this function is complete, the new options will be passed to unlayer to be refreshed.
 *
 * @param linkType The key of the customLinkProvider
 * @return {Promise<void>} After the options are loaded.
 */
export async function refreshLinkType(linkType) {
    const provider = customLinkTypeProviders[linkType];

    if (provider?.options) {
        await loadLookupOptions(linkType, provider.options);
    }
}

/**
 * Quick way of defining a customLink property that uses the linkType as a convention for resolving the i18n messagse.
 *
 * This will create a drop-down field. See unlayer documentation for linkTypes
 * @param linkType The key for the customLinkType
 * @param options A list of options used to populate the drop-down box.  If null, then a loading placeholder will be used.
 */
export function LinkTypeSelectField({ linkType, options }) {
    return {
        name: linkType,
        label: intl.get(`customLinks.${linkType}.field.label`, { defaultMessage: 'Page' }),
        inputType: null,
        placeholderText: intl.get(`customLinks.${linkType}.field.placeholder`, { defaultMessage: 'Select page' }),
        options: options ?? [{
            value: '',
            label: intl.get('customLinks.loadingPlaceholder', { defaultMessage: '...' }),
        }],
    };
}

/**
 * Internal.  Loads all options for a single linkType by invoking any cross-frame requests that are defined.
 *
 * After the options have been loaded, this function will call `refreshUnlayerLinkTypes`.
 *
 * @param linkType The key for the link type
 * @param options Cross frame loaders for all properties defined by this linkType.  In most cases, this will be a single key.
 * @return {Promise<void>}
 */
async function loadLookupOptions(linkType, options) {
    if (options) {
        const optionsData = await Promise.all(Object.entries(options).map(async ([fieldName, loader]) => {
            const data = await loader();

            return [fieldName, data];
        }));

        optionsData.forEach(([fieldName, data]) => {
            currentCustomLinkTypes[linkType].fields.find((f) => f.name === fieldName).options = data;
        });
        refreshUnlayerLinkTypes();
    }
}

/**
 * Sets the current state for a single custom link type.  Used internally.
 *
 * Calling this method will also trigger unlayer to reload the link types.
 * @param name The key for this type
 * @param config What would be passed to unlayer
 */
function setLinkType(name, config) {
    config.name = name;
    currentCustomLinkTypes[name] = config;
    refreshUnlayerLinkTypes();
}

/**
 * Passes updated linkTypes to unlayer.  Used internally.
 */
function refreshUnlayerLinkTypes() {
    unlayer.setLinkTypes([
        ...Object.values(currentCustomLinkTypes),
        {
            name: 'web',
            attrs: {
                href: '{{href}}',
                target: '{{target}}',
            },
            fields: [
                {
                    name: 'href',
                    label: 'URL',
                    defaultValue: '',
                    inputType: 'url',
                    placeholderText: null,
                    options: [],
                },
                {
                    name: 'target',
                    label: 'Target',
                    defaultValue: '_blank',
                    inputType: null,
                    placeholderText: null,
                    options: [
                        { value: '_blank', label: 'New Tab' },
                        { value: '_top', label: 'Same Tab' },
                    ],
                },
            ],
        },
        {
            name: 'phone',
            enabled: false,
        },
        {
            name: 'email',
            enabled: false,
        },
        {
            name: 'sms',
            enabled: false,
        },
    ]);
}

// Used for testing
export function clearLinkTypes() {
    customLinkTypeProviders = {};
    currentCustomLinkTypes = {};
}

