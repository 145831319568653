/* eslint-disable react/no-deprecated */
import React from 'react';

import isPlainObject from 'lodash/isPlainObject';
import classNames from 'classnames';

import Buttons from './formFields/Buttons';
import fieldRenderers from './formFields';
import { buildFormCSS } from '@/unlayer-tools/unlayer-form-tool/renderer/build-form-css';

export class Viewer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            errors: {},
        };
    }

    componentWillMount() {
        this.populateInputs();
    }

    componentWillReceiveProps() {
        this.populateInputs();
    }

    populateInputs() {
        this.inputs = {};

        const {
            values: { fields },
        } = this.props;
        const defaultValue = (field) => {
            switch (field.type) {
            case 'hidden':
                return field.value;

            case 'dropdown':
                return field.options.split('\n')[0];

            case 'checkbox':
                return [];
            }
        };

        fields.forEach(
            (field) =>
                (this.inputs[field.name] = {
                    label: field.label,
                    type: field.type,
                    required: field.required,
                    show_label: field.show_label,
                    placeholder_text: field.placeholder_text,
                    value: defaultValue(field),
                    meta_data: field.meta_data || {},
                    sms_opt_in: field.sms_opt_in || false,
                }),
        );
    }

    render() {
        // Form
        const {
            values: {
                formWidth: _formWidth,
                formLayoutType = 'dynamic',
                stackOnMobile,
            },
        } = this.props;

        // Fields
        const {
            values: {
                fields,
            },
        } = this.props;

        // Buttons
        const {
            values: {
                buttonPosition = '2',
                buttonText,
                buttonWidth: _buttonWidth,
            },
        } = this.props;

        const formWidth =
            _formWidth && isPlainObject(_formWidth)
                ? {
                    autoWidth: !!_formWidth.autoWidth,
                    fullWidthOnMobile: !!_formWidth.fullWidthOnMobile,
                    width:
                        _formWidth.width && typeof _formWidth.width === 'string'
                            ? _formWidth.width
                            : '100%',
                }
                : {
                    autoWidth: false,
                    fullWidthOnMobile: false,
                    width:
                        _formWidth && typeof _formWidth === 'string'
                            ? _formWidth
                            : '100%',
                };

        const buttonWidth =
            _buttonWidth && isPlainObject(_buttonWidth)
                ? {
                    autoWidth: !!_buttonWidth.autoWidth,
                    fullWidthOnMobile: !!_buttonWidth.fullWidthOnMobile,
                    width:
                        _buttonWidth.width && typeof _buttonWidth.width === 'string'
                            ? _buttonWidth.width
                            : '50%',
                }
                : {
                    autoWidth: false,
                    fullWidthOnMobile: false,
                    width:
                        _buttonWidth && typeof _buttonWidth === 'string'
                            ? _buttonWidth
                            : '50%',
                };

        const formClassName = classNames({
            'form': true,
            'form-layout-dynamic': formLayoutType === 'dynamic',
            'form-layout-dynamic-stacked':
                stackOnMobile && formLayoutType === 'dynamic',
            'form-layout-standard': formLayoutType === 'standard',
            'full-width-mobile': !!formWidth.fullWidthOnMobile,
            'v-form-width-width': true,
            'v-form-width-max-width': true,
        });

        const fieldClassName = classNames({
            'field-container': true,
            'field-container-dynamic': formLayoutType === 'dynamic',
            'field-container-stacked': stackOnMobile && formLayoutType === 'dynamic',
        });

        const buttonClassName = classNames({
            'submit-btn': true,
            'full-width-mobile': !!buttonWidth.fullWidthOnMobile,
            'v-button-width-width': true,
            'v-button-width-max-width': true,
        });

        return (
            <div className="form-container">
                <div className={formClassName}>
                    {fields.map((field, index) => {
                        const Field = fieldRenderers[field.type];

                        if (!Field) {
                            console.warn(`No field renderer for${field.type}`);

                            return '<div></div>';
                        } else {
                            return (
                                <Field
                                    key={`field-${index}`}
                                    field={field}
                                    className={fieldClassName}
                                    error={this.state.errors[field.name]}
                                    fieldContainerStyles={{
                                        gridColumn: `span ${field.width ?? '2'}`,
                                        alignSelf: 'end',
                                    }}
                                    onResetError={() => this.resetError(field.name)}
                                    onValue={(value, checked) =>
                                        this.setFieldValue(field.name, value, checked)
                                    }
                                />
                            );
                        }
                    })}
                    <Buttons
                        buttonClassName={buttonClassName}
                        buttonContainerStyles={{
                            gridColumn: `span ${buttonPosition}`,
                            alignSelf: 'end',
                        }}
                        buttonText={buttonText}
                    />
                </div>
            </div>
        );
    }

    resetError(name) {
        let errors = {
            ...this.state.errors,
        };

        delete errors[name];

        this.setState({ errors });
    }

    setFieldValue(name, value, checked) {
        const field = this.inputs[name];

        switch (field.type) {
        case 'checkbox':
            if (checked) {
                field.value.push(value);
            } else {
                field.value.splice(field.value.indexOf(value), 1);
            }
            break;

        default:
            field.value = value;
        }

        this.resetError(name);
    }
}

export const exporters = {
    web(values) {
        const { smartForm: { renderString } } = values;

        return renderString;
    },
    email() {
        return '';
    },
};

export const head = {
    css: (values, bodyValues) => buildFormCSS({ values, bodyValues }),
    js: () => undefined,
};

export default {
    Viewer,
    exporters,
    head,
};
