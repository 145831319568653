import { KeapCustomLinkTypes, registerLookupLinkType } from '@/unlayer-tools/custom-link-types';

export function registerMarketingSiteLinkType({ options } = {}) {
    registerLookupLinkType({
        linkType: KeapCustomLinkTypes.marketingSite,
        options, mapper: ({ label, value: marketingSite }) => {
            const { siteUrl, id, appId } = marketingSite;
            const value = `${siteUrl}#appId=${appId};siteRef=${id}`;

            return {
                label,
                value,
            };
        },
    });
}

export function registerMarketingPageLinkType({ options } = {}) {
    registerLookupLinkType({
        linkType: KeapCustomLinkTypes.marketingPage,
        options, mapper: ({ label, value: marketingPage }) => {
            const { pageUrl, id, siteId, appId } = marketingPage;
            const value = `${pageUrl}#appId=${appId};siteRef=${siteId};pageRef=${id}`;

            return {
                label,
                value,
            };
        },
    });
}

