import React from 'react';
import { Button, Form, Input, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/fontawesome-free-solid';
import Modal from 'react-modal';
import { ModalContent } from '../../styles';

import style from '../../styles/FormFieldModal.scss';

import slugify from 'slugify';

import { getFieldEditor } from './index';

const defaultField = {
    type: 'text',
    name: '',
    label: '',
    value: '',
    options: '',
    defaultToFirstOption: false,
    show_label: true,
    deletable: true,
};

const defaultHiddenField = {
    type: 'hidden',
    name: '',
    label: '',
    value: '',
    options: '',
    defaultToFirstOption: false,
    show_label: true,
    deletable: true,
};

export class FieldModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            field: props.hidden ? Object.assign({}, defaultHiddenField) : Object.assign({}, defaultField),
            errors: {},
        };

        this.allowFieldTypeChange = () => {
            const { field } = this.state;

            if (field && field.type === 'hidden_group') return false;
            if (this.isPersistedField(field)) return false;

            return true;
        };

        this.isPersistedField = (field) => {
            const { fields } = this.props;

            if (fields.filter((x) => field.name && field.name == x.name).length > 0) return true;

            return false;
        };

        this.canDelete = () => {
            const { fieldCount, field } = this.props;

            // If deletable is set in the field and it's not true, field should not be deletable
            if (!field || (typeof field.deletable != 'undefined' && !field.deletable)) return false;
            // If the field type is email and it's the last email field in the form, field should not be deletable
            if (fieldCount == 1) return false;

            return true;
        };
    }

    handleSubmit() {
        const { updateField, addField, toggle } = this.props;
        const { field } = this.state;

        let errors = {};

        if (!field.name.trim()) errors['name'] = 'Name cannot be blank';
        if (!field.label.trim()) errors['label'] = 'Label cannot be blank';

        if (Object.keys(errors).length > 0) {
            this.setState({ errors });

            return;
        }

        if (this.props.field) {
            updateField(this.props.field.name, field);
        } else {
            addField(field);
        }

        toggle();
    }

    componentWillReceiveProps(nextProps) {
        let value;

        if (nextProps.field) {
            value = Object.assign({}, nextProps.field);
        } else {
            if (nextProps.hidden) {
                value = Object.assign({}, defaultHiddenField);
            } else {
                value = Object.assign({}, defaultField);
            }
        }
        this.setState({ field: value });
    }

    handleNameChange(value) {
        const { usedFieldNames, fields } = this.props;
        let { field, errors } = this.state;

        value = value.trim().split(' ').join('_');

        // Auto generate name based on label
        let proposedName = slugify(value, { lower: true, replacement: '_', remove: /[^\w-]+/g });
        const existingNames = usedFieldNames.concat(fields.map((f) => f.name)).filter((x, i, a) => a.indexOf(x) == i);
        const pattern = new RegExp(`^${proposedName}(_\\d+)?$`, 'gi');

        // Make sure that field name is not already used
        let highest = 0;

        existingNames.map((existingName) => {
            if (existingName && existingName.match(pattern)) {
                return existingName.toLowerCase();
            }
        }).filter(Boolean).map((existingName) => {
            if (existingName == proposedName) {
                if (highest == 0) highest = 1;
            } else {
                let num;

                try { num = parseInt(existingName.split('_').pop()); }
                catch (err) { num = 0; }
                if (highest < num) highest = num;
            }
        });

        let finalName;

        if (highest == 0) {
            finalName = proposedName;
        } else {
            highest++;
            finalName = `${proposedName}_${highest}`;
        }

        field['name'] = finalName;

        let newErrors = errors;

        delete newErrors.name;

        this.setState({ field, errors: newErrors });
    }

    handleFieldChange(key, value) {
        let { field, errors } = this.state;

        field[key] = value;

        let newErrors = errors;

        if (key === 'type') {
            newErrors = {};
            field['placeholder_text'] = '';

            if (value === 'date') {
                field['date_format'] = 'DD-MM-YYYY';
            } else {
                delete field.date_format;
            }
        }

        if (key === 'label') {
            delete newErrors.label;

            // Generate a name based on label
            // Only do this for fields that are not persisted
            if (!this.isPersistedField(field)) {
                this.handleNameChange(value);
            }
        }

        if ( key === 'hidden' && value === true ) {
            field.required = false;
        }

        if ( key === 'required_opt_in' && value === true ) {
            field.required_opt_in = true;
        }

        if ( key === 'url_param_text' ) {
            if (value) {
                field.url_param_text = value.trim();
            } else {
                delete field.url_param_text;
            }
        }

        this.setState({ field, errors: newErrors });
    }

    render() {
        const { isOpen, toggle, deleteField, hidden, urlParamsEnabled } = this.props;
        const { field, errors } = this.state;

        let FieldEditor = getFieldEditor(field.type);

        return (
            <Modal
                isOpen={isOpen}
                onRequestClose={toggle}
                ariaHideApp={false}
                style={{
                    overlay: {
                        zIndex: '120',
                        backgroundColor: 'rgba(0, 0, 0, 0.60)',

                    },
                    content : {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        padding: '0px',
                        minWidth: '500px',
                        borderRadius:'0.75rem',
                    },
                }}
            >
                <div className={style.modalTitleContainer}>
                    <div onClick={toggle}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="icon" aria-hidden="true"><path fillRule="evenodd" d="M18.707 6.707a1 1 0 0 0-1.414-1.414L12 10.586 6.707 5.293a1 1 0 0 0-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 1 0 1.414 1.414L12 13.414l5.293 5.293a1 1 0 0 0 1.414-1.414L13.414 12z"></path></svg>
                    </div>
                    <p className={style.fieldEditTitleStyle}>
                        {this.props.field ? 'Edit field' : 'New field'}
                    </p>
                </div>


                <ModalContent>
                    <Form>
                        {this.allowFieldTypeChange() && <div className={style.columnStyle}>
                            <Label className={style.labelStyle}>Field Type</Label>
                            <Input  className={style.inputField} type="select" value={field.type} disabled={hidden} onChange={(e) => this.handleFieldChange('type', e.target.value)}>
                                <option value="text">Text Field (One Line)</option>
                                <option value="textarea">Text Box (Multiple Lines)</option>
                                <option value="email">Email Field</option>
                                <option value="date">Date Field</option>
                                <option value="dropdown">Dropdown</option>
                                <option value="checkbox">Checkbox</option>
                                <option value="number">Number Field</option>
                                <option value="hidden">Hidden Field</option>
                            </Input>
                        </div>}

                        {field && (
                            <FieldEditor
                                field={field}
                                errors={errors}
                                handleNameChange={this.handleNameChange.bind(this)}
                                handleFieldChange={this.handleFieldChange.bind(this)}
                                urlParamsEnabled={urlParamsEnabled}/>
                        )}
                    </Form>
                </ModalContent>

                <div className={style.rowStyle} style={{ borderTop: '1px solid rgba(0, 0, 0, .09)', padding: '16px 10px' }}>
                    <div className={this.canDelete() ? 'col-8' : ''}>
                        <Button className={style.primaryButton} onClick={this.handleSubmit.bind(this)}>
                            {this.props.field ? 'Update field' : 'Add field'}
                        </Button>
                        <Button className={style.secondaryButton} onClick={toggle}>Cancel</Button>
                    </div>

                    {this.canDelete() && (
                        <div>
                            <Button className={style.warningButton} data-qa="delete" onClick={() => deleteField(field.name, hidden)}>
                                <FontAwesomeIcon icon={faTrash} />
                                <span style={{ marginLeft: '10px' }}>Delete</span>
                            </Button>
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
}

export default FieldModal;
