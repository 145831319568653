import { intl } from '@/shared/intl';

export const buttonToolOpts = () => ({
    button: {
        position: 2,
        title: intl.get('tool.properties.button', { defaultMessage: 'Button' }),
        options: {
            buttonText: {
                label: intl.get('tool.properties.buttonTextLabel', { defaultMessage: 'Text' }),
                defaultValue: intl.get('tool.properties.defaultSubmit', { defaultMessage: 'Download File' }),
                widget: 'text',
            },
            buttonPosition: {
                label: intl.get('tool.properties.formPosition', { defaultMessage: 'Form Position' }),
                defaultValue: '2',
                widget: 'rowlayout',
                enabled: false,
            },
            buttonColors: {
                label: intl.get('tool.properties.buttonColors', { defaultMessage: 'Color' }),
                defaultValue: {
                    color: '#FFF',
                    backgroundColor: '#2C84D2',
                    hoverColor: '#FFF',
                    hoverBackgroundColor: '#1D5A8F',
                },
                widget: 'button_color',
            },
            buttonAlign: {
                label: intl.get('tool.properties.alignment', { defaultMessage: 'Alignment' }),
                defaultValue: 'center',
                widget: 'alignment',
            },
            buttonWidth: {
                label: intl.get('tool.properties.width', { defaultMessage: 'Width' }),
                defaultValue: {
                    autoWidth: false,
                    fullWidthOnMobile: false,
                    width: '40%',
                },
                widget: 'auto_width',
                overrideAllowed: ['mobile'],
            },
            buttonLineHeight: {
                label: intl.get('Line Height', { defaultMessage: 'Line Height' }),
                defaultValue: '120%',
                widget: 'line_height',
            },
            buttonFontSize: {
                label: intl.get('tool.properties.buttonColors', { defaultMessage: 'Font Size' }),
                defaultValue: '14px',
                widget: 'px',
            },
            buttonFontFamily: {
                label: intl.get('tool.properties.fontFamily', { defaultMessage: 'Font Family' }),
                defaultValue: {
                    label: 'Arial',
                    value: 'arial,helvetica,sans-serif',
                },
                widget: 'font_family',
            },
            buttonBorder: {
                label: intl.get('tool.properties.border', { defaultMessage: 'Border' }),
                defaultValue: {
                    borderTopWidth: '0',
                    borderTopStyle: 'solid',
                    borderTopColor: '#CCC',
                    borderLeftWidth: '0',
                    borderLeftStyle: 'solid',
                    borderLeftColor: '#CCC',
                    borderRightWidth: '0',
                    borderRightStyle: 'solid',
                    borderRightColor: '#CCC',
                    borderBottomWidth: '0',
                    borderBottomStyle: 'solid',
                    borderBottomColor: '#CCC',
                },
                widget: 'border',
                hidden: true,
            },
            buttonBorderRadius: {
                label: intl.get('tool.properties.roundedBorder', { defaultMessage: 'Rounded Border' }),
                defaultValue: '4px',
                widget: 'border_radius',
                hidden: true,
            },
            buttonPadding: {
                label: intl.get('tool.properties.padding', { defaultMessage: 'Padding' }),
                defaultValue: '10px 10px',
                widget: 'padding',
                hidden: true,
            },
            buttonMargin: {
                label: intl.get('tool.properties.margin', { defaultMessage: 'Margin' }),
                defaultValue: '8px 0px 0px',
                widget: 'margin',
                hidden: true,
            },
        },
    },
});
