import React from 'react';
import classNames from 'classnames';
import { Col, Row } from 'reactstrap';

/**
 * Mimics an unlayer panel widget by applying the correct classes to the label and form controls
 *
 * @param label The label for this widget
 * @param inline Whether the label and control should be displayed inline
 * @param children The actual form controls
 *
 * @constructor
 */
export function UnlayerFormField({ label = '', inline = false, children = [] }) {
    return (
        <Row className="blockbuilder-widget">
            <Col small={inline ? 6 : 12}>
                <div className={classNames({
                    'blockbuilder-widget-label': true,
                    // Bootstrap class, adds padding on the bottom of the label, used when the controls are not
                    // inline
                    'pb-2': !inline,
                })}>
                    <label className="blockbuilder-label-primary">{label}</label>
                </div>
                {children}
            </Col>
        </Row>
    );
}
