import React, { Component } from 'react';
import { FormFieldContainer } from '@/unlayer-tools/unlayer-form-tool/renderer/formFields/FormFieldContainer';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import amplitude from '@/hosting/keap-analytics/keap-track-amplitude';
import { initializeAnalytics } from '@/hosting/keap-analytics';
import { KEAP_EVENT_NAMES } from '@/hosting/keap-analytics/keap-analytics.constants';
import { executeFrameRequest } from '@/unlayer-tools/requests/frame-request';
import style from '../../editors/styles/FormEditorStyle.scss';

class MultipleTags extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedValues: this.props.value,
            options: this.props.data.field.options,
        };
        initializeAnalytics();
        amplitude.logEvent(KEAP_EVENT_NAMES.tagAppliedToForm, {
            ...this.state.selectedValues,
        });
    }

    onUpdateSelectedValues() {
        this.props.updateValue(this.state.selectedValues);
    }

    handleOnChange = (value) => {
        this.setState({
            ...this.state,
            selectedValues: [...value],
        }, this.onUpdateSelectedValues);
    }

    handleOnCreateOption = async (inputValue = '') => {
        const newOption = await executeFrameRequest('createTagRequest', { inputValue });

        if (newOption) {
            this.setState({
                ...this.state,
                options: [...this.state.options, newOption],
                selectedValues: [...this.state.selectedValues, newOption],
            }, this.onUpdateSelectedValues);
        }
    }

    render() {
        const { data, error } = this.props;
        const { field } = data;

        const Select = field.isCreatable ? CreatableSelect : ReactSelect;

        return (<FormFieldContainer
            formControl={
                <Select
                    value={this.state.selectedValues}
                    options={this.state.options}
                    isMulti
                    isClearable={false}
                    onChange={this.handleOnChange}
                    onCreateOption={this.handleOnCreateOption}
                    placeholder={this.state.selectedValues?.length ? '' : field.placeholder}
                    onRemove={this.handleOnRemove}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary25: 'lightgrey',
                            primary: '#DCDCDC',
                        },
                    })}
                />
            }
            error={error}
            field={field}
            className={field.className}
            labelStyle={style.labelStyle}
        />);
    }
}

export const MultiSelectTagsEditor = {
    name: 'multiselectTags',
    Widget: MultipleTags,
};
