export const pixelEvents = [
    'View Content',
    'Contact',
    'Lead',
    'Schedule',
    'Submit Application',
    'Complete Registration',
    'Add to Cart',
    'Initiate Checkout',
    'Add Payment Information',
    'Purchase',
    'Start Trial',
    'Subscribe',
    'Add to Wishlist',
    'Customize Product',
    'Donate',
    'Find Location',
    'Search',
    'Other Event Type',
];
