// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FwFhv4TD1kmqnHLFHgGE{font-size:12px;font-weight:500;line-height:24px;color:#515151;font-family:inherit}.C0sc8svLJrxmpbkelcZS{display:flex;place-content:space-between;align-items:center}.yWQjmuLpGMJIiX1Ti7Yn{transform:scale(0.7);margin:7px 0}.xuXpCzsFT7pLKOcwwsrl{line-height:14px;font-size:11px;margin-top:0.5rem;margin-bottom:1rem;opacity:0.6;font-family:inherit;color:#1e1e1e}.gHXSkQlcwGnLfiwWbP2q{display:flex;flex:1 1 0%;line-height:1.25rem;padding:0.75rem 1rem;font-size:13px;font-weight:400;color:#7d7d7d;background-color:#fff;border:1px solid #d4d4d4;border-radius:2px;width:100%}\n", "",{"version":3,"sources":["webpack://./src/unlayer-tools/custom-tools/appointment-tool/calendly/calendly.scss"],"names":[],"mappings":"AAAA,sBACE,cAAe,CACf,eAAgB,CAChB,gBAAiB,CACjB,aAAsB,CACtB,mBAAoB,CACrB,sBAGC,YAAa,CACb,2BAA4B,CAC5B,kBACF,CAAC,sBAGC,oBAAqB,CACrB,YAAa,CACd,sBAGC,gBAAiB,CACjB,cAAe,CACf,iBAAkB,CAClB,kBAAmB,CACnB,WAAY,CACZ,mBAAoB,CACpB,aAAsB,CACvB,sBAGC,YAAa,CACb,WAAY,CACZ,mBAAoB,CACpB,oBAAqB,CACrB,cAAe,CACf,eAAgB,CAChB,aAAyB,CACzB,qBAAoC,CACpC,wBAAoC,CACpC,iBAAkB,CAClB,UAAW","sourcesContent":[".labelStyle {\n  font-size: 12px;\n  font-weight: 500;\n  line-height: 24px;\n  color: rgb(81, 81, 81);\n  font-family: inherit;\n}\n\n.labelWrapperHorizontal {\n  display: flex;\n  place-content: space-between;\n  align-items: center\n}\n\n.toggleButton {\n  transform: scale(0.7);\n  margin: 7px 0;\n}\n\n.labelText {\n  line-height: 14px;\n  font-size: 11px;\n  margin-top: 0.5rem;\n  margin-bottom: 1rem;\n  opacity: 0.6;\n  font-family: inherit;\n  color: rgb(30, 30, 30);\n}\n\n.inputField {\n  display: flex;\n  flex: 1 1 0%;\n  line-height: 1.25rem;\n  padding: 0.75rem 1rem;\n  font-size: 13px;\n  font-weight: 400;\n  color: rgb(125, 125, 125);\n  background-color: rgb(255, 255, 255);\n  border: 1px solid rgb(212, 212, 212);\n  border-radius: 2px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelStyle": "FwFhv4TD1kmqnHLFHgGE",
	"labelWrapperHorizontal": "C0sc8svLJrxmpbkelcZS",
	"toggleButton": "yWQjmuLpGMJIiX1Ti7Yn",
	"labelText": "xuXpCzsFT7pLKOcwwsrl",
	"inputField": "gHXSkQlcwGnLfiwWbP2q"
};
export default ___CSS_LOADER_EXPORT___;
