export function logger(name) {
    return {
        info: logFactory('log', name),
        error: logFactory('error', name),
        warn: logFactory('warn', name),
    };
}

function logFactory(level, name) {
    // eslint-disable-next-line no-console
    return console[level].bind(console, name);
}
