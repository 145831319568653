import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { intl } from '@/shared/intl';
import style from '../../editors/styles/FormEditorStyle.scss';

const VirtualFieldWarninng = ({ alertMessage }) => {
    const [isVisible, setIsVisible] = useState(true);

    return (
        <>
            { isVisible && <div className='virtual-field-alert' color='secondary'>
                <div className='alert-container'>
                    <div className='alert-icon'>
                        <FontAwesomeIcon icon={faExclamationCircle} />
                    </div>
                    <div className={style.labelStyle}>
                        {alertMessage}
                    </div>
                    <p className='alert-close' onClick={() => setIsVisible(false)}>{intl.get('unlayer.tool.form.virtual.field.alert.close', { defaultMessage: 'x' })}</p>
                </div>
            </div> }
        </>
    );
};

export default VirtualFieldWarninng;
