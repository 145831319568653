/* eslint-disable no-class-assign */
import React from 'react';
import { PanelSection } from '@/unlayer-tools/components/pages-panel-bar';
import close from '@/icons/close.svg';
import style from './PageSettings.scss';
import formStyle from '@/shared/styles/form.scss';
import PanelHeader from '@/unlayer-tools/components/PanelHeader';
import { Icon } from '@/shared/components/Icon';
import { Input, Label } from 'reactstrap';
import { requestUnlayerPageDetails } from '@/unlayer-tools/requests/pages-requests';
import { logger } from '@/unlayer-tools/log';
import { intl } from '@/shared/intl';

const log = logger('PageSettings');
const MAX_NAME_LENGTH = 40;

export default class PageSettings extends React.Component {
    constructor(props, context) {
        super(props, context);
        const { page: { id, name: pageName = '', seoDescription = '', builderJson = {} } = {} } = props ?? {};

        if (!(props.editMode && seoDescription)) {
            this.state = {
                pageName,
                seoDescription: builderJson.options?.seoDescription || '',
            };
        } else {
            this.state = {
                pageName,
                seoDescription,
            };
        }
        this.loadPageDetails(id);
    }

    async loadPageDetails(id) {
        const details = await requestUnlayerPageDetails(id) ?? {};

        log.info('Got response: ', details);
        const { name: pageName, seoDescription } = details;

        if (pageName || seoDescription) {
            this.setState((state)=>({
                ...state,
                pageName, seoDescription,
            }));
        }
    }

    nameInputFocus = (e, nameInputFocused) => {
        this.setState({ nameInputFocused });
        if (!nameInputFocused) this.handleUserInput(e);
    }

    handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({ [name]: value });
    }

    render() {
        const onClose = () => {
            this.props.onClose(this.state);
        };

        const inputFieldLeaveHandler = () => {
            this.props.onClose(this.state);
        };

        return (
            <div className={style.PageSettings} onMouseLeave={inputFieldLeaveHandler}>
                <PanelSection>
                    <PanelHeader title="Settings" trailing={[
                        <div key="close"><Icon data-testid="close-page-settings" style={{ cursor: 'pointer' }} icon={close} onClick={onClose} /></div>,
                    ]} />

                    <div className={style.pageSettingsBody}>
                        <div className={formStyle.field}>
                            <Label className={style.labelStyle}>
                                {
                                    intl.get('pagesPanel.edit.pageName.label', { defaultMessage: 'Page Name' })
                                }
                            </Label>
                            <div>
                                <Input data-testid="page-pageName-field" className={style.inputField} name="pageName"
                                    value={this.state.pageName}
                                    maxLength={MAX_NAME_LENGTH}
                                    onFocus={(e) => this.nameInputFocus(e, true)}
                                    onBlur={(e) => this.nameInputFocus(e, false)}
                                    onChange={(e) => this.handleUserInput(e)}
                                />
                            </div>
                            {this.state.nameInputFocused && (
                                <div className={style.inputCountDisplay}>
                                    <div>
                                        {
                                            intl.get('pagesPanel.edit.pageName.length.message', { defaultMessage: 'Page Name cannot exceed 40 characters' })
                                        }
                                    </div>
                                    <div>{this.state.pageName.length}/{MAX_NAME_LENGTH}</div>
                                </div>
                            )}
                        </div>

                        <div className={formStyle.field}>
                            <Label className={style.labelStyle}>{intl.get('pagesPanel.edit.seoDescription.label', { defaultMessage: 'SEO Description' })}</Label>
                            <Input className={style.inputField}  placeholder={intl.get('pagesPanel.edit.seoDescription.placeholder', { defaultMessage: 'Describe the contents of this page' })}
                                type="textarea" name="seoDescription"
                                data-testid="page-seoDescription-field"
                                value={this.state.seoDescription}
                                onChange={(e) => this.handleUserInput(e)} />
                        </div>
                    </div>
                </PanelSection>
            </div>
        );
    }
}
