// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QA7R2FwVmj6iA1LEi9NL{margin-bottom:16px}.fjTlVE2SEknnWMD142nX{font-size:12px}\n", "",{"version":3,"sources":["webpack://./src/shared/styles/form.scss"],"names":[],"mappings":"AACA,sBACI,kBAAmB,CACtB,sBAGG,cAAe","sourcesContent":["\n.field {\n    margin-bottom: 16px;\n}\n\n.fieldLabel {\n    font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": "QA7R2FwVmj6iA1LEi9NL",
	"fieldLabel": "fjTlVE2SEknnWMD142nX"
};
export default ___CSS_LOADER_EXPORT___;
