import { intl } from '@/shared/intl';

export const appointmentToolOpts = () => ({
    appointments: {
        position: 1,
        title: intl.get('tool.properties.appointmentTextLabel', { defaultMessage: 'Appointments' }),
        options: {
            appointmentAction: {
                defaultValue: '',
                widget: 'dropdown',
            },
        },
    },
});
