// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s7yy57_EVOENsbm1IK6U{height:500px;background-color:#DDF1FA;display:flex;border:2px solid #1D91D2}.s7yy57_EVOENsbm1IK6U svg{filter:invert(50%) sepia(64%) saturate(5763%) hue-rotate(179deg) brightness(101%) contrast(77%);margin:auto}\n", "",{"version":3,"sources":["webpack://./src/shared/components/ToolPlaceholder.scss","webpack://./src/shared/styles/_filters.scss"],"names":[],"mappings":"AAEA,sBACI,YAAa,CACb,wBAAyB,CACzB,YAAa,CACb,wBAAyB,CAJ7B,0BCOI,+FAAgG,CDC5F,WAAY","sourcesContent":["@import '~@/shared/styles/_filters.scss';\n\n.toolPlaceholder {\n    height: 500px;\n    background-color: #DDF1FA;\n    display: flex;\n    border: 2px solid #1D91D2;\n\n    svg {\n        @include placeholderIconFilter;\n        margin: auto;\n    }\n}","@mixin black600Filter() {\n    filter: invert(32%) sepia(0%) saturate(0%) hue-rotate(65deg) brightness(91%) contrast(83%);\n}\n\n@mixin black500Filter {\n    filter: invert(56%) sepia(0%) saturate(57%) hue-rotate(199deg) brightness(96%) contrast(88%);\n}\n\n@mixin placeholderIconFilter {\n    filter: invert(50%) sepia(64%) saturate(5763%) hue-rotate(179deg) brightness(101%) contrast(77%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolPlaceholder": "s7yy57_EVOENsbm1IK6U"
};
export default ___CSS_LOADER_EXPORT___;
