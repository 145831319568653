import React from 'react';
import { executeFrameRequest } from '@/unlayer-tools/requests/frame-request';
import { intl } from '@/shared/intl';
import { Panel, PanelHeader, PanelSection } from '@/unlayer-tools/components/pages-panel-bar';
import { Label } from 'reactstrap';
import formStyle from '@/shared/styles/form.scss';
import style from '../abtesting/ABTestingPanel.scss';
import Toggle from 'react-toggle';


export function createABTestingPanel(options = {}) {
    const ABPanel = buildABTestingPanelComponent(options);

    const ABTestingPanel = {
        name: 'funnel_abtesting',
        label: intl.get('abPanel.panelName', { defaultMessage: 'Admin' }),
        icon: 'fa-cog',
        supportedDisplayModes: ['web'],
        renderer: {
            Panel: ABPanel,
        },
    };

    return ABTestingPanel;
}

export function buildABTestingPanelComponent(options) {
    class ABPanelComponent extends React.Component {
        constructor(props, context) {
            super(props, context);
            const groups = (options.audience || '').split(',');

            this.state = {
                blueGroupEnabled: groups.includes('BLUE'),
                greenGroupEnabled: groups.includes('GREEN'),
                redGroupEnabled: groups.includes('RED'),
            };
        }

        onUpdateSettings = () => {
            const settings =  {
                blueGroupEnabled: this.state.blueGroupEnabled,
                greenGroupEnabled: this.state.greenGroupEnabled,
                redGroupEnabled: this.state.redGroupEnabled,
            };

            executeFrameRequest('updateAdminSettings', { settings });
        };

        handleBlueGroupOnChange = (event) => {
            this.setState({ blueGroupEnabled: event.target.checked }, this.onUpdateSettings);
        };

        handleGreenGroupOnChange = (event) => {
            this.setState({ greenGroupEnabled: event.target.checked }, this.onUpdateSettings);
        };

        handleRedGroupOnChange = (event) => {
            this.setState({ redGroupEnabled: event.target.checked }, this.onUpdateSettings);
        };

        render() {
            return (
                <div className="ABTestingPanel">
                    <Panel>
                        <PanelSection>
                            <PanelHeader title={intl.get('abPanel.panelName', { defaultMessage: 'Admin' })} />
                            <div className={style.abTestingLabelWrapper}>
                                <Label className={formStyle.fieldLabel}>{intl.get('abPanel.green.toggle.label', { defaultMessage: 'Display for Green Group (KEAP Testing)' })}</Label>
                                <Toggle
                                    className={style.ABTestingPanelToggleButton}
                                    checked={this.state.greenGroupEnabled}
                                    onChange={(e) => this.handleGreenGroupOnChange(e) }
                                />
                            </div>

                            <div className={style.abTestingLabelWrapper}>
                                <Label className={formStyle.fieldLabel}>{intl.get('abPanel.blue.toggle.label', { defaultMessage: 'Display for Blue Group' })}</Label>
                                <Toggle
                                    className={style.ABTestingPanelToggleButton}
                                    checked={this.state.blueGroupEnabled}
                                    onChange={(e) => this.handleBlueGroupOnChange(e) }
                                />
                            </div>
                            <div className={style.abTestingLabelWrapper}>
                                <Label className={formStyle.fieldLabel}>{intl.get('abPanel.red.toggle.label', { defaultMessage: 'Display for Red Group' })}</Label>
                                <Toggle
                                    className={style.ABTestingPanelToggleButton}
                                    checked={this.state.redGroupEnabled}
                                    onChange={(e) => this.handleRedGroupOnChange(e) }
                                />
                            </div>
                        </PanelSection>
                    </Panel>
                </div>
            );
        }
    }

    return ABPanelComponent;
}
