// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ha8iVdm_uygKhbAJtfB3{font-size:12px;font-weight:500;line-height:24px;color:#515151;font-family:inherit}.dpwMalB03u2D5YCpQMeQ{display:flex;justify-content:space-between;flex-direction:row;margin:8px 0px;align-items:center}\n", "",{"version":3,"sources":["webpack://./src/shared/components/ToogleSwitch/ToggleSwitch.scss"],"names":[],"mappings":"AAAA,sBACI,cAAe,CACf,eAAgB,CAChB,gBAAiB,CACjB,aAAsB,CACtB,mBAAoB,CACvB,sBAGG,YAAa,CACb,6BAA8B,CAC9B,kBAAmB,CACnB,cAAe,CACf,kBAAmB","sourcesContent":[".labelStyle {\n    font-size: 12px;\n    font-weight: 500;\n    line-height: 24px;\n    color: rgb(81, 81, 81);\n    font-family: inherit;\n}\n\n.toggleSwitch {\n    display: flex;\n    justify-content: space-between;\n    flex-direction: row;\n    margin: 8px 0px;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelStyle": "Ha8iVdm_uygKhbAJtfB3",
	"toggleSwitch": "dpwMalB03u2D5YCpQMeQ"
};
export default ___CSS_LOADER_EXPORT___;
