import React, { useEffect } from 'react';
import { ToolPlaceholder } from '@/shared/components/ToolPlaceholder';
import calendarIcon from '@/icons/calendar.svg';
import { encodeTrackingEvent } from '@/shared/tracking-event-util';

export const CalendlyTypeViewer = (props = {}) => {
    const { values:
      { calendlyLink, nameUrlParam, emailUrlParam, tagApply, calendlyFormSlug, pixelEvent },
    } = props;

    const { values: { _meta: { htmlID } } } = props;

    useEffect(() => {
        const script = document.createElement('script');

        script.type = 'text/javascript';
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.async = true;
        document.body.appendChild(script);

        const htmlWidgetContainer = document.getElementById(htmlID);

        if (!htmlWidgetContainer) {
            return;
        }

        const widgetContainer = htmlWidgetContainer.querySelector('div[id^="calendly-container-"]');

        script.onload = () => {
            window.Calendly.initInlineWidget({
                url: calendlyLink,
                parentElement: widgetContainer,
            });
        };

        return () => {
            if (script) document.body.removeChild(script);

            if (widgetContainer) {
                while (widgetContainer.firstChild) {
                    widgetContainer.removeChild(widgetContainer.firstChild);
                }
            }
        };
    }, [calendlyLink]);

    if (calendlyLink !== '') {
        let tagData = tagApply ? tagApply.map((tag) => tag.value).join(',') : '';
        const idSuffix = extractLastPartOfUrl(calendlyLink);
        const pixelTrackingEvent = encodeTrackingEvent(pixelEvent);

        return (
            // Added data-url to this div so that we can grab calendly url from it in the hosted scripts
            <div id={`calendly-container-${idSuffix}`} className='calendly-viewer-container' data-calendly-url={calendlyLink}
                data-calendly-name-param={nameUrlParam} data-calendly-email-param={emailUrlParam}
                data-calendly-tag-apply={tagData} data-calendly-form-slug={calendlyFormSlug} data-calendly-pixel-event={pixelTrackingEvent}></div>
        );
    }
    else {
        return (
            <ToolPlaceholder className="choose-appointment" icon={calendarIcon} />
        );
    }
};

function extractLastPartOfUrl(url) {
    const urlObject = new URL(url);
    const pathParts = urlObject.pathname.split('/');

    return pathParts[pathParts.length - 1];
}
