/* eslint-disable no-console */
import React from 'react';
import CardTile from './CardTile';
import {
    requestCreatePage,
    requestCopyPage,
    requestDeletePage,
    requestFocusPage,
    requestSavePage,
    requestSortPages,
    requestPageList,
} from '../../requests/pages-requests';
import { Panel, PanelGray, PanelHeader, PanelSection } from '@/unlayer-tools/components/pages-panel-bar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import debounce from 'lodash/debounce';
import PageSettings from '@/unlayer-tools/panels/pages-panel/PageSettings';
import { KeapCustomLinkTypes, refreshLinkType } from '@/unlayer-tools/custom-link-types';
import { intl } from '@/shared/intl';
import { Oval } from 'react-loader-spinner';

export function createPagesPanelSettings( allowEditPages, isCopyPageEnabled, maxPageCount) {

    const PagesPanel = buildPagesPanelComponent( allowEditPages, isCopyPageEnabled, maxPageCount);

    const PagesPanelSettings = {
        name: 'funnel_pages',
        label: intl.get('pagesPanel.panelName', { defaultMessage: 'Pages' }),
        icon: 'fa-layer-group',
        // todo: Add the correct svg logo
        // icon: 'https://storage.googleapis.com/is-unlayer-tools.appspot.com/builds/layers.svg',
        supportedDisplayModes: ['web', 'popup'],
        renderer: {
            Panel: PagesPanel,
        },
    };

    return PagesPanelSettings;
}

export function buildPagesPanelComponent( allowEditPages, isCopyPageEnabled, maxPageCount) {
    class PagesPanel extends React.Component {
        constructor(props, context) {
            super(props, context);
            this.state = {
                pages: [],
                isEditing: false,
                currentName: null,
                currentlySaving: {},
                allowEditPages: allowEditPages,
                maxPageCount: maxPageCount,
                isLoading: true,
            };

            this.resortPagesDebounced = debounce((newPages)=> {
                return requestSortPages(newPages);
            }, 1000);
        }

        async componentDidMount() {
            const responseData = await requestPageList();

            if (!responseData?.selectedPage) {
                this.setState({ pages: responseData?.pageData, isLoading: false, selectedPage: 0 });
            } else {
                const index = responseData?.pageData.findIndex((page) => page.id === responseData?.selectedPage);

                this.setState({ pages: responseData?.pageData, isLoading: false, selectedPage: index });

                await requestFocusPage(responseData?.selectedPage);
            }
        }

        movePage(dragIndex, hoverIndex) {
            const { pages } = this.state;
            let newPages = pages.slice(0);
            let temp = newPages[dragIndex];

            newPages[dragIndex] = newPages[hoverIndex];
            newPages[hoverIndex] = temp;
            this.setState((state) => ({
                ...state,
                pages: newPages,
            }));

            this.resortPagesDebounced(newPages.map(({ id }) => id));

            return true;
        }

        setPageStage(pageId, updates) {
            this.setState(({ pages, ...other }) => ({
                ...other,
                pages: pages.map((page) => page.id === pageId ? { ...page, ...updates } : page),
            }));
        }

        async editPageSettings(pageId, currentName) {
            // noinspection ES6MissingAwait
            this.selectPage(pageId, this.state.selectedPage);

            this.setState((state) => ({
                ...state,
                isEditing: true,
                currentName,
            }));
        }

        async addPage() {
            let savedPage;

            this.setState({ isCreating: true });

            try {
                if (this.state.pages.length < this.state.maxPageCount) {
                    savedPage = await requestCreatePage();

                    if (savedPage) {
                        this.setState({
                            pages: [...this.state.pages, savedPage],
                            selectedPage: this.state.pages.length,
                        });
                        await Promise.all[requestFocusPage(savedPage.id), refreshLinkType(KeapCustomLinkTypes.marketingPage)];
                    }
                } else {
                    this.setState({ errors: [...this.state.errors ?? [], 'max_page_count'] });
                    throw new Error(`Cannot create more than ${this.state.maxPageCount} pages.`);
                }
            } catch (e) {
                console.warn(`Error ${e}`);
            } finally {
                this.setState({ isCreating: false });
            }
        }

        async copyPage(existingPageId) {
            const { pages } = this.state;
            let copiedPage;

            this.setState({ isCreating: true });

            try {
                if (this.state.pages.length < this.state.maxPageCount) {
                    await requestFocusPage(existingPageId);

                    copiedPage = await requestCopyPage(existingPageId);

                    if (copiedPage) {
                        this.setState({
                            pages: [...pages, copiedPage],
                            selectedPage: pages.length,
                            isCreating: false,
                        });
                        await Promise.all([requestFocusPage(copiedPage.id), refreshLinkType(KeapCustomLinkTypes.marketingPage)]);
                    }
                } else {
                    this.setState({ errors: [...this.state.errors ?? [], 'max_page_count'] });
                    throw new Error(`Cannot create more than ${this.state.maxPageCount} pages.`);
                }
            } catch (e) {
                console.warn(`Error ${e}`);
            }
        }

        async deletePage(pageId) {
            const { pages } = this.state;
            const deleteConfirmation = await requestDeletePage(pageId);

            if (deleteConfirmation) {
                this.setState({
                    ...this.state,
                    pages: pages.filter((page) => page.id !== pageId),
                    selectedPage: 0,
                });
            }
            await Promise.all[ requestFocusPage(pages[0]?.id), refreshLinkType(KeapCustomLinkTypes.marketingPage)];
        }

        selectPage(selectedPage, pageIndex) {
            if (selectedPage !== pageIndex) {
                this.setState((state) => ({
                    ...state,
                    selectedPage: pageIndex,
                }));

                const page = this.state.pages[pageIndex];

                return requestFocusPage(page.id);
            }
        }

        async updatePage(currentPageId, { pageName, seoDescription }) {

            this.setState((state) => ({
                ...state,
                pages: state.pages.map((page) => {
                    if (page.id === currentPageId) {
                        return {
                            ...page,
                            name: pageName,
                            seoDescription,
                        };
                    } else {
                        return page;
                    }
                }),
                currentlySaving: {
                    ...state.currentlySaving,
                    [currentPageId]: true,
                },
                isEditing: false,
            }));

            try {
                await requestSavePage(currentPageId, { pageName, seoDescription });
                await refreshLinkType(KeapCustomLinkTypes.marketingPage);
            } finally {
                this.setState((state) => ({
                    ...state,
                    currentlySaving: {
                        ...state.currentlySaving,
                        [currentPageId]: false,
                    },
                }));
            }
        }

        get currentPage() {
            const { pages, selectedPage } = this.state;

            return pages[selectedPage] ?? 1;
        }

        render() {
            const { isEditing = false, selectedPage, isLoading, pages, isCreating = false, isDeleting = false } = this.state;

            const { currentPage } = this;

            if (isEditing) {
                return (
                    <PanelGray>
                        <PageSettings page={currentPage} editMode={isEditing} onClose={({ pageName, seoDescription }) => {
                            return this.updatePage(currentPage.id, { pageName, seoDescription });
                        }} />
                    </PanelGray>
                );
            } else {
                return (
                    <Panel>
                        <PanelSection>
                            {
                                <PanelHeader title={intl.get('pagesPanel.visiblePageHeader', { defaultMessage: 'Visible Pages' })} trailing={
                                    <React.Fragment>
                                        {pages.length > (Math.floor(this.state.maxPageCount / 2)) &&
                                            (pages.length === this.state.maxPageCount
                                                ? <span style={{ color: '#CCCCCC' }}>{`${pages.length}/${this.state.maxPageCount} `}</span>
                                                : <span>{`${pages.length}/${this.state.maxPageCount} `}</span>)
                                        }
                                        {isCreating || pages.length === this.state.maxPageCount
                                            ? <FontAwesomeIcon style={{ cursor: 'pointer', color: '#CCCCCC' }} icon={faPlus} />
                                            : <FontAwesomeIcon data-testid='create-page' style={{ cursor: 'pointer' }} icon={faPlus}
                                                onClick={() => this.addPage() } />}
                                    </React.Fragment>
                                } />
                            }

                        </PanelSection>

                        <div style={{ padding: '8px', width: '100%' }}>
                            <React.Fragment>
                                {!isLoading ? pages.map((page, index) =>
                                    <CardTile
                                        data-testid={`page-tile-${page.id}`}
                                        key={page.id}
                                        index={index}
                                        canDelete={this.state.pages.length > 1 && !isDeleting}
                                        canCopy={this.state.pages.length < this.state.maxPageCount}
                                        onEdit={() => this.editPageSettings(page.id, page.name)}
                                        movePage={this.movePage.bind(this)}
                                        onDeletePage={() => this.deletePage(page.id)}
                                        onCopyPage={() => this.copyPage(page.id)}
                                        allowEditPages={this.state.allowEditPages}
                                        isCopyPageEnabled={isCopyPageEnabled}
                                        onSelect={() => this.selectPage(selectedPage, index)}
                                        isFocused={index === selectedPage}
                                        isSaving={this.state.currentlySaving[page.id]}
                                        pageName={page.name}
                                        pageId={page.id} />) : (<Oval
                                    visible={true}
                                    height="30"
                                    width="30"
                                    color="#000000"
                                    secondaryColor='gray'
                                    ariaLabel="oval-loading"
                                    radius="1"
                                    wrapperStyle={{ display: 'block', width: '100%', textAlign: 'center' }}
                                    wrapperClass='loaderStyle'
                                />)}


                                {isCreating && <>
                                    <div>
                                        <Oval
                                            visible={true}
                                            height="30"
                                            width="30"
                                            color="#000000"
                                            secondaryColor='gray'
                                            ariaLabel="oval-loading"
                                            radius="1"
                                            wrapperStyle={{ display: 'block', width: '100%', textAlign: 'center' }}
                                            wrapperClass='loaderStyle'
                                        />
                                    </div>
                                </>}
                            </React.Fragment>
                        </div>
                    </Panel>

                );
            }

        }
    }

    return PagesPanel;
}
