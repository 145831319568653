import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { listenForResizeEvents, sharedToolOpts, SizedContent } from '@/unlayer-tools/SizedContent';
import { EnvironmentKind, KeapEnvConfig } from '@/shared/keap-env-config';
import checkoutIcon from '@/icons/checkout_forms.svg';
import { intl } from '@/shared/intl';
import { requestCreateCheckoutForm } from '@/unlayer-tools/requests/pages-requests';
import { ToolPlaceholder } from '@/shared/components/ToolPlaceholder';


/**
 * Renderer for the keap checkout form tool.  This renders a sized iframe with the embedded URL.
 *
 * The frame will also listen for resize events from the embedded frame to adjust the size of the iframe.
 */
export function KeapCheckoutFormViewer(props = {}) {
    const { values: { checkoutForm } = {} } = props;

    const isNewCheckout = checkoutForm?.value?.checkoutFormName === 'add-new';

    if (checkoutForm && checkoutForm.label && !isNewCheckout) {
        const { value: { checkoutFormUrl, appId, environment = EnvironmentKind.integration } = {} } = checkoutForm;

        return (
            <SizedContent {...props}>
                <iframe src={`${KeapEnvConfig[EnvironmentKind[environment]].EMBED_FORM_URL}/checkout/${appId}/${checkoutFormUrl}?embed=true`}
                    data-form-slug={`${appId}/${checkoutFormUrl}`}
                    style={{ border:'none', minHeight: '700px', minWidth: '100%', width: '100%' }}
                    name={`checkout-${checkoutFormUrl}`} scrolling="no" frameBorder="0" marginHeight="0px"
                    marginWidth="0px" width="100%" height="100%" />
            </SizedContent>
        );
    } else {
        return <ToolPlaceholder className='choose-checkout-form' icon={checkoutIcon} />;
    }
}

/**
 * Unlayer tool definition for keap checkout forms.
 *
 * This can be passed directly to `unlayer.registerTool`
 */
export const KeapCheckoutFormTool = (currentPage) => ({
    name: 'keap#checkout_form',
    label: intl.get('tool.checkoutForm.label', { defaultMessage: 'Checkout' }),
    icon: `<svg height="36" width="36" viewBox="${checkoutIcon.viewBox}">
        <use xlink:href="#${checkoutIcon.id}" />
    </svg>`,
    options: {
        keapCheckout: {
            position: 1,
            title: intl.get('tool.checkoutForm.panel.label', { defaultMessage: 'Checkout Forms' }),
            options: {
                checkoutForm: {
                    label: intl.get('tool.checkoutForm.select.label', { defaultMessage: 'Checkout Form' }),
                    widget: 'keap#select',
                },

            },
        },
        ...sharedToolOpts(),
    },
    transformer: (values, source) => {
        const { value } = source;

        if (value.value && value.value?.checkoutFormName === 'add-new') {
            requestCreateCheckoutForm(currentPage.id, values);
            values.checkoutForm = undefined;
        }

        return values;
    },
    supportedDisplayModes: ['web', 'popup'],
    values: {
        fieldWidth: '100%',
    },
    renderer: {
        Viewer: KeapCheckoutFormViewer,
        exporters: {
            web(values) {
                const props = { values, displayMode: 'web' };

                return ReactDOMServer.renderToStaticMarkup(<KeapCheckoutFormViewer {...props} />);
            },
        },
        head: {
            css() {
                return `
              .choose-checkout-form {
                height: 500px;
                background-color: #DDF1FA;
                text-alignment: center;
                vertical-alignment: center;
                display: flex;
                border: 2px solid #1D91D2;
              }
              .choose-checkout-form > svg {
                margin: auto;
              }
              `;
            },
            js(values = {}) {
                const { checkoutForm: { value: { checkoutFormUrl, appId } = {} } = {} }  = values;

                return listenForResizeEvents(`${appId}/${checkoutFormUrl}`);
            },
        },
    },
});

