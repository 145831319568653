import { executeFrameRequest } from '@/unlayer-tools/requests/frame-request';

export function requestBrandLogos() {
    return executeFrameRequest('getBrandLogos', null, '*', 'parent');
}

export function requestBrandColors() {
    return executeFrameRequest('getBrandColors', null, '*', 'parent');
}

export function requestBrandingCenter() {
    return executeFrameRequest('showBrandingServiceCenter', null, '*', 'parent');
}
