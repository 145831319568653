import React from 'react';

import { FormFeedback, Input, Label } from 'reactstrap';
import style from '../../../styles/FormFieldModal.scss';


class NoPlaceholderFieldSettings extends React.Component {
    render() {
        const { field, errors, handleFieldChange } = this.props;
        const { label, show_label, required } = field;

        return (
            <div className={style.columnStyle}>
                <div className={style.rowStyle}>
                    <Label className={style.labelStyle}>Field label</Label>
                    <div className={style.inputFieldContainer}>
                        <Input
                            type="checkbox"
                            checked={!show_label}
                            onChange={(e) => handleFieldChange('show_label', !e.target.checked)} />
                        <span className={style.labelStyle}>Hide label</span>
                    </div>
                </div>

                <Input
                    invalid={!!errors['label']}
                    type="text"
                    name="label"
                    value={label}
                    onChange={(e) => handleFieldChange('label', e.target.value)} />
                {errors['label'] && <FormFeedback>{errors['label']}</FormFeedback>}

                <div className={style.rowStyle}>
                    <div className={style.inputFieldContainer}>
                        <Input
                            type="checkbox"
                            checked={required}
                            onChange={(e) => handleFieldChange('required', e.target.checked)} />{' '}
                        <span className={style.labelStyle}>Required field</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default NoPlaceholderFieldSettings;
