import DefaultFieldSettings from './editors/DefaultFieldSettings';
import NoPlaceholderFieldSettings from './editors/NoPlaceholderFieldSettings';

const FIELD_TYPE_EDITORS = {
    text: DefaultFieldSettings,
    email: DefaultFieldSettings,
    dropdown: DefaultFieldSettings,
    textarea: DefaultFieldSettings,
    date: NoPlaceholderFieldSettings,
    datetime: NoPlaceholderFieldSettings,
    checkbox: NoPlaceholderFieldSettings,
    radio: NoPlaceholderFieldSettings,
    hidden: NoPlaceholderFieldSettings,
    hidden_group: NoPlaceholderFieldSettings,
    number: DefaultFieldSettings,
};

export function getFieldEditor(type) {
    return FIELD_TYPE_EDITORS[type] ?? DefaultFieldSettings;
}
