import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { EnvironmentKind, KeapEnvConfig } from '@/shared/keap-env-config';
import {
    listenForResizeEvents,
    sharedToolOpts,
    SizedContent,
} from '@/unlayer-tools/SizedContent';
import calendarIcon from '@/icons/calendar.svg';
import { intl } from '@/shared/intl';
import { ToolPlaceholder } from '@/shared/components/ToolPlaceholder';
import {
    CalendlyTypeViewer,
} from '@/unlayer-tools/custom-tools/appointment-tool/calendly/CalendlyTypeViewer';
import {
    CalendlyOptions,
} from '@/unlayer-tools/custom-tools/appointment-tool/calendly/constants/calendly-options';
import {
    calendlyViewerCss,
} from '@/unlayer-tools/custom-tools/appointment-tool/calendly/calendly-viewer-css';
import { v4 } from 'uuid';

/**
 * Renderer for the keap appointment tool.  This renders a sized iframe with the embedded URL.
 *
 * The frame will also listen for resize events from the embedded frame to adjust the size of the iframe.
 */
// eslint-disable-next-line no-unused-vars
export function KeapAppointmentViewer(props = {}) {
    let { values: { appointmentType, calendlyLink, useCalendlyLink } } = props;

    if (!useCalendlyLink && appointmentType?.value && !!Object.keys(appointmentType).length) {
        const { value: { bookingLink, environment = EnvironmentKind.integration } } = appointmentType;
        const url = `${KeapEnvConfig[EnvironmentKind[environment]].EMBED_FORM_URL}/booking/${bookingLink}?embed=true`;

        return (
            <SizedContent {...props}>
                <iframe src={url}
                    data-form-slug={bookingLink}
                    style={{ border:'none', minHeight: '700px', minWidth: '100%', width: '100%' }}
                    name={`booking-${bookingLink}`} scrolling="no" frameBorder="0" marginHeight="0px"
                    marginWidth="0px" width="100%" height="100%" />
            </SizedContent>
        );
    }
    else if (useCalendlyLink && calendlyLink !== '') {
        return (
            <SizedContent {...props}>
                <CalendlyTypeViewer {...props}/>
            </SizedContent>
        );
    }
    else {
        return (
            <ToolPlaceholder className="choose-appointment" icon={calendarIcon} />
        );
    }
}

/**
 * Unlayer tool definition for keap appointments.
 *
 * This can be passed directly to `unlayer.registerTool`
 */
export const KeapAppointmentTool = (funnelsCalendlyEmbed, tags) => ({
    name: 'keap#appointment_form',
    label: intl.get('tool.bookingForm.label'),
    icon: `<svg height="33" width="33" viewBox="${calendarIcon.viewBox}">
        <use xlink:href="#${calendarIcon.id}" />
    </svg>`,
    options: {
        keapBooking: {
            position: 1,
            title: intl.get('tool.bookingForm.panel.label', { defaultMessage: 'Keap Appointments' } ),
            options: {
                appointmentType: {
                    label: intl.get('tool.bookingForm.select.label', { defaultMessage: 'Appointments' } ),
                    widget: 'keap#select',
                },
            },
        },
        calendlyBooking: {
            position: 2,
            title: intl.get('unlayer.tool.booking.calendly', { defaultMessage: 'Calendly Appointments' }),
            options: { ...CalendlyOptions({ funnelsCalendlyEmbed: funnelsCalendlyEmbed, tags: tags }) },
        },
        ...sharedToolOpts(),
    },
    propertyStates: (values) => {
        if (values.calendlyLink === '' || !values.useCalendlyLink) {
            return {
                tagApply: {
                    enabled: false,
                },
                pixelEvent: {
                    enabled: false,
                },
                nameUrlParam: {
                    enabled: false,
                },
                emailUrlParam: {
                    enabled: false,
                },
            };
        }
    },
    transformer: (values) => {
        let { calendlyFormSlug, useCalendlyLink } = values;

        if (useCalendlyLink && !calendlyFormSlug) {
            calendlyFormSlug = v4().toString();
        }

        return {
            ...values,
            calendlyFormSlug,
        };
    },
    supportedDisplayModes: ['web', 'popup'],
    values: {
        fieldWidth: '100%',
    },
    renderer: {
        Viewer: KeapAppointmentViewer,
        exporters: {
            web(values) {
                const props = { values: {
                    ...values, funnelsCalendlyEmbed, tags,
                }, displayMode: 'web' };

                return ReactDOMServer.renderToStaticMarkup(<KeapAppointmentViewer {...props} />);
            },
        },
        head: {
            css() {
                return calendlyViewerCss;
            },
            js({ appointmentType = {} }) {
                const { value: { bookingLink } = {} } = appointmentType;

                return listenForResizeEvents(bookingLink);
            },
        },
    },
});
