import TextField from './TextField';
import DateField from './DateField';
import EmailField from './EmailField';
import DropdownField from './DropdownField';
import TextArea from './TextArea';
import CheckboxField from './CheckboxField';
import HiddenField from './HiddenField';
import NumberField from './NumberField';
import TelephoneField from './TelephoneField';
import DateTimeField from './DateTimeField';
import RadioField from './RadioField';
import BooleanField from './BooleanField';

export default {
    text: TextField,
    email: EmailField,
    dropdown: DropdownField,
    textarea: TextArea,
    date: DateField,
    datetime: DateTimeField,
    checkbox: CheckboxField,
    radio: RadioField,
    hidden: HiddenField,
    hidden_group: HiddenField,
    number: NumberField,
    tel: TelephoneField,
    date_time: DateTimeField,
    boolean: BooleanField,
};
