// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UJmViFO8ds4e8QntE_oZ{display:flex;flex:1 1 0%;line-height:1.25rem;padding:0.75rem 1rem;font-size:13px;font-weight:400;color:#7d7d7d;background-color:#fff;border:1px solid #d4d4d4;border-radius:2px;width:100%}.z8NUc_6R6ooR68zzgoI2{font-size:12px;font-weight:500;line-height:24px;color:#515151;font-family:inherit}.HFVKSyx5kZnjNEzhLaAK{padding:10px 0px;border:1px solid #d4d4d4;border-radius:2px;margin:4px 0px;word-break:break-all}.veXvoA8CtKCjpWVdDajR{display:flex}\n", "",{"version":3,"sources":["webpack://./src/unlayer-tools/unlayer-form-tool/editors/styles/FormEditorStyle.scss"],"names":[],"mappings":"AAAA,sBACI,YAAa,CACb,WAAY,CACZ,mBAAoB,CACpB,oBAAqB,CACrB,cAAe,CACf,eAAgB,CAChB,aAAyB,CACzB,qBAAoC,CACpC,wBAAoC,CACpC,iBAAkB,CAClB,UAAW,CACd,sBAGG,cAAe,CACf,eAAgB,CAChB,gBAAiB,CACjB,aAAsB,CACtB,mBAAoB,CACvB,sBAGG,gBAAiB,CACjB,wBAAoC,CACpC,iBAAkB,CAClB,cAAe,CACf,oBAAqB,CACxB,sBAGG,YAAa","sourcesContent":[".inputField {\n    display: flex;\n    flex: 1 1 0%;\n    line-height: 1.25rem;\n    padding: 0.75rem 1rem;\n    font-size: 13px;\n    font-weight: 400;\n    color: rgb(125, 125, 125);\n    background-color: rgb(255, 255, 255);\n    border: 1px solid rgb(212, 212, 212);\n    border-radius: 2px;\n    width: 100%;\n}\n\n.labelStyle {\n    font-size: 12px;\n    font-weight: 500;\n    line-height: 24px;\n    color: rgb(81, 81, 81);\n    font-family: inherit;\n}\n\n.fieldRowContainer {\n    padding: 10px 0px;\n    border: 1px solid rgb(212, 212, 212);\n    border-radius: 2px;\n    margin: 4px 0px;\n    word-break: break-all;\n}\n\n.draggableCardContainer {\n    display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputField": "UJmViFO8ds4e8QntE_oZ",
	"labelStyle": "z8NUc_6R6ooR68zzgoI2",
	"fieldRowContainer": "HFVKSyx5kZnjNEzhLaAK",
	"draggableCardContainer": "veXvoA8CtKCjpWVdDajR"
};
export default ___CSS_LOADER_EXPORT___;
