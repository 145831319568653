import React from 'react';
import { intl } from '@/shared/intl/index';

/**
 * Renders an intl message. Built to work with react-intl-universal but still uses tag syntax
 */
export class Intl extends React.Component {
    constructor(props, context) {
        super(props, context);

        const { token } = this.props;

        if (!token) {
            throw Error('Missing required property token');
        }

        if (!intl.isReady) {
            intl.init();
        }

        this.state = {
            msg: intl.get(token),
        };
    }

    render() {
        return <>{this.state.msg}</>;
    }
}

/**
 * Ensures that children are rendered after intl codes are loaded.
 *
 * This is required because we can't load i18n at the root of the react app since we don't control it.  We only get to render leaf nodes.
 */
export class WithIntl extends React.Component {
    constructor(props, context) {
        super(props, context);

        if (!intl.isReady) {
            intl.init();
        }
    }

    render() {
        return <>{this.props.children}</>;
    }
}
