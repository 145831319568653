export const DEFAULT_FORM_FIELDS = [
    { name: 'birthday', label: 'Birthday', type: 'date' },
    { name: 'company', label: 'Company', type: 'text' },
    { name: 'email', label: 'Email', type: 'email' },
    { name: 'name', label: 'Name', type: 'text' },
    { name: 'phone_number', label: 'Phone Number', type: 'tel' },
    { name: 'website', label: 'Website', type: 'text' },
    { name: 'zip_code', label: 'Zip Code', type: 'text' },
];


export const FIELD_TYPE_CONSTANT = {
    VIRTUAL_FIELD: 'virtual_field',
    HIDDEN_FIELD: 'hidden_field',
    HIDDEN_GROUP: 'hidden_group',
    CUSTOM_FIELD: 'Custom fields',
};
